import { Avatar, Button, Col, Row, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import lodash from 'lodash'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Paragraph from 'antd/lib/typography/Paragraph'
import './style.scss'
import LogModal from '../LogModal'
import ConfirmModal from '../../Modal/ConfirmModal'
import { getUrlPathname } from '../../../utils/util'
import Skeleton from 'react-loading-skeleton'

interface HeadingProps {
	orderDetail: any
	reOrder: () => void
	isReOrdering: boolean
	statuses: any
	cancelOrder: (callback: () => void) => void
	isCancelOrdering: boolean
	showBoxChat: () => void
	loadingCreateThread: boolean
	loading: boolean
}

const Heading: React.FC<HeadingProps> = (props) => {
	const { orderDetail, statuses, reOrder, isReOrdering, cancelOrder, isCancelOrdering, loadingCreateThread, loading } = props
	const { t } = useTranslation()
	const [showModal, setShowModal] = useState(false)
	const codeOrder = lodash.get(orderDetail, 'code', '--')
	const productImage = lodash.get(orderDetail, 'image', '')
	const providerLink = lodash.get(orderDetail, 'provider.domain')
	const providerCustomer = lodash.get(orderDetail, 'customer', '--')
	const logo = lodash.get(orderDetail, 'provider.logo', '')
	const [isCancellable, setCancellable] = useState<boolean>(false)
	const [isConfirm, setConfirm] = useState<boolean>(false)
	const statusOj = statuses.find(({ code }: any) => code === lodash.get(orderDetail, 'status'))
	const handleVisibleLogOrderModal = () => {
		setShowModal(true)
	}
	const handleCancel = () => {
		setShowModal(false)
	}
	useEffect(() => {
		if (orderDetail) {
			let status = statuses.find((x: any) => x.code === orderDetail.status)
			setCancellable(lodash.get(status, 'cancellable', false))
		}
	}, [orderDetail, statuses])

	const onCancel = () => {
		cancelOrder(() => {
			setConfirm(false)
		})
	}

	return (
		<>
			<Row className='pd12 bg-color-white dpl-flex justify-content-between align-items-center'>
				<Col className='dpl-flex justify-content-between align-items-start'>
					<div className='dpl-flex mg-r-64'>
						{!loading ? (
							<Avatar shape='square' size={48} src={productImage} className='border-radius3 bd-none-impt mg-r-8' />
						) : (
							<Skeleton width={48} height={48} className='mg-r-8' />
						)}
						<div className='dpl-flex flex-col'>
							{!loading ? (
								<span className='dpl-flex align-items-center robotoregular mg-r-2'>
									{codeOrder && (
										<Paragraph
											className='robotomedium mg-0 txt-color-primary fsz-16 robotoregular line-h-24 order-heading'
											copyable={{
												text: codeOrder,
												icon: [
													<Tooltip
														title={
															<span className='robotoregular fsz-12 txt-color-black line-h-20'>
																Copy
															</span>
														}
														color='#FBB739'>
														<i className='far fa-copy line-h-14 w-12' />
													</Tooltip>,
													<Tooltip
														title={
															<span className='robotoregular fsz-12 txt-color-black line-h-20'>
																Copied
															</span>
														}
														color='#FBB739'>
														<i className='far fa-check fsz-12 line-h-14 w-12' />
													</Tooltip>,
												],
												tooltips: [false, false],
											}}>
											{`#${codeOrder}`}
										</Paragraph>
									)}
								</span>
							) : (
								<Skeleton width={150} height={22} />
							)}
							{!loading ? (
								<span
									className='px-12 h-25 border-radius25 align-items-center dpl-flex justify-content-center'
									style={{ backgroundColor: statusOj?.color ? statusOj?.color : '#09B8AF' }}>
									<span className='robotoregular txt-color-white fsz-12 line-h-20'>
										{lodash.get(statusOj, 'name')}
									</span>
								</span>
							) : (
								<Skeleton borderRadius={25} width={150} height={24} />
							)}
						</div>
					</div>
					<div className='dpl-flex flex-col align-items-start robotoregular mg-r-64'>
						<span className='dpl-flex align-items-center txt-size-h7 line-h-22 txt-color-black'>
							{!loading ? (
								<Avatar size={12} src={logo} shape={'circle'} style={{ border: 'none' }} className='mg-r-4' />
							) : (
								<Skeleton width={12} height={12} circle className='mg-r-4' />
							)}
							<span className='mg-r-4'>{!loading ? providerLink : <Skeleton width={150} height={22} />}</span>
							{!loading ? <span className='mg-r-4'>|</span> : <Skeleton width={1} height={16} className='mg-r-4' />}
							<span className={'fsz-14 robotoregular txt-color-black'}>
								{!loading ? providerCustomer : <Skeleton width={60} height={22} />}
							</span>
						</span>
						<span className='dpl-flex align-items-center txt-color-blue1 txt-size-h7 cursor-pointer'>
							{!loading ? (
								<i className='far fa-file-alt fsz-11 line-h-12 item-link mg-r-4' />
							) : (
								<Skeleton width={14} height={14} className='mg-r-4' />
							)}
							<span onClick={handleVisibleLogOrderModal} className='txt-underline line-h-22 item-link'>
								{!loading ? t('orderDetail.log') : <Skeleton width={80} height={20} />}
							</span>
						</span>
					</div>

					<div className='dpl-flex flex-col align-items-start robotoregular'>
						{!loading ? (
							<Button
								loading={loadingCreateThread}
								disabled={loadingCreateThread}
								onClick={() => props.showBoxChat()}
								className='dpl-flex align-items-center border-radius4 btn-create-thread-chat-by-order txt-size-h7 mg-r-9 mg-t-10'
								icon={<i className='fa-regular fa-comment' />}>
								<span>{t('button.createThreadChat')}</span>
							</Button>
						) : (
							<Skeleton width={120} height={32} borderRadius={4} />
						)}
					</div>
				</Col>
				<Col className='dpl-flex align-items-center robotoregular'>
					{!loading ? (
						<Button
							className='dpl-flex align-items-center btn-df btn-df-secondary-bg-blue txt-size-h7 mg-r-9'
							icon={<i className='far fa-plus mg-r-9' />}>
							<Link
								className='link-create-ticket'
								to={`/claims/create?orderCode=${codeOrder}&providerCode=${lodash.get(
									orderDetail,
									'provider.code'
								)}&customerCode=${lodash.get(orderDetail, 'providerUsername')}&back=${getUrlPathname(
									window.location
								)}`}>
								{t('orderDetail.btCreateTicket')}
							</Link>
						</Button>
					) : (
						<Skeleton width={120} height={32} className='mg-r-9' />
					)}
					<i className='far fa-ellipsis-v mg-r-9 fsz-14 line-h-14 txt-color-gray2' />
					{!loading ? (
						<Button
							loading={isReOrdering}
							disabled={isReOrdering}
							onClick={reOrder}
							className='dpl-flex align-items-center btn-df btn-df-primary-bg-orange txt-size-h7 mg-r-6'>
							{t('orderDetail.reOrder')}
						</Button>
					) : (
						<Skeleton width={120} height={32} className=' mg-r-6' />
					)}
					{isCancellable &&
						(!loading ? (
							<Button
								disabled={isCancelOrdering}
								onClick={() => setConfirm(true)}
								className='dpl-flex align-items-center btn-df btn-df-quaternary-bg-red txt-size-h7'>
								{t('orderDetail.cancel')}
							</Button>
						) : (
							<Skeleton width={120} height={32} />
						))}
				</Col>
				{isConfirm &&
					(!loading ? (
						<ConfirmModal
							onCancel={() => setConfirm(false)}
							visible={isConfirm}
							onSubmit={onCancel}
							loading={isCancelOrdering}
							content={t('orderDetail.confirmCancel')}
						/>
					) : (
						<Skeleton width={120} height={32} />
					))}
				{showModal && (
					<LogModal
						orderCurrency={lodash.get(orderDetail, 'currency', '')}
						visible={showModal}
						onCancel={handleCancel}
					/>
				)}
			</Row>
		</>
	)
}

export default Heading

