import { Avatar, Button, Divider, Modal, notification, Table } from 'antd'
import lodash from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import EmptyDataFilter from '../../../../components/Empty/EmptyDataFilter'
import { formatMoneyByUnit } from '../../../../core/helpers/string'
import { ProductInterface } from '../../../../Interface/ShipmentInterface'
import ShipmentProductInterface from '../../../../Interface/ShipmentProductInterface'
import { shipmentRepository } from '../../../../repositories/ShipmentRepository'
import ShipmentImageDefault from '../../../../resources/images/shipment-ubox-default.png'
import CreateProductModal from './CreateProductModal'

interface OrderItemsProps {
	items: ProductInterface[]
	currency: string
	currencyMarketPalace?: string
	refetch: () => void
	productUpdatable: boolean
}

const Products: React.FC<OrderItemsProps> = (props) => {
	const { t } = useTranslation()
	const { code } = useParams()
	const { items, refetch, productUpdatable } = props
	const [visible, setVisible] = useState(false)
	const [loadingProductDelete, setLoadingProductDelete] = useState(false)
	const [product, setProduct] = useState<ShipmentProductInterface | undefined>()
	const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)

	const deleteProductHandler = async (productCode: string) => {
		setLoadingProductDelete(true)
		try {
			await shipmentRepository.deleteProduct(code!, productCode)
			notification.success({ message: t('shipment.deleteSuccess') })
		} catch (err: any) {
			notification.error({ message: err?.response.detail ?? 'Đã có lỗi xảy ra, vui lòng thử lại!' })
		} finally {
			setLoadingProductDelete(false)
			setVisibleDeleteModal(false)
			refetch()
		}
	}

	const columns = [
		{
			title:  <span className='fsz-14 line-h-22 txt-color-gray4'>{t('orderDetail.productName')}</span>,
			key: 'name',
			width: 564,
			render: (record: any) => {
				const properties = lodash.get(record, 'variantProperties', [])
				return (
					<div className='dpl-flex align-items-start'>
						<a href={lodash.get(record, 'url')} className='cursor-pointer' target='_blank' rel='noreferrer'>
							<Avatar
								shape={'square'}
								size={40}
								src={record.image ? record.image : ShipmentImageDefault}
								className={'flex-shrink-0 border-radius-none mg-r-16'}
								style={{ border: 'none' }}
							/>
						</a>
						<div className='dpl-flex flex-col pd-l-2'>
							<a
								href={lodash.get(record, 'url')}
								className='cursor-pointer fsz-14 txt-color-black robotomedium'
								target='_blank'
								rel='noreferrer'>
								<span>{lodash.get(record, 'name', '--')}</span>
							</a>
							<span className='txt-color-black mgr6 fsz-14'>{lodash.get(record, 'translatedName', '---')}</span>
							<span className='dpl-flex flex-col pd-l-2'>
								{properties.length > 0
									? properties.map((pro: any, idx: number) => (
											<span
												className='txt-color-gray4 robotoregular fsz-14 line-h-22'
												key={idx}>{`${pro.name} : ${pro.value}`}</span>
									  ))
									: '---'}
							</span>
							<div className='flex align-items-center fsz-14 mg-bt-6'>
								<i className='fa-light fa-store mg-r-4'></i>
								<span className='txt-color-black mgr6 fsz-14'>
									{record.merchantName ? record.merchantName : '---'}
								</span>
							</div>
							<div className='flex align-items-center fsz-14 pd-l-2'>
								<i className='fa-light fa-location-dot mg-r-4'></i>
								<span className='txt-color-black mgr6 fsz-14'>
									{record.merchantContact ? record.merchantContact : '---'}
								</span>
							</div>
						</div>
					</div>
				)
			},
		},
		{
			title: <span className='fsz-14 line-h-22 txt-color-gray4'>{t('shipment.unitPrice')}</span>,
			key: 'price',
			align: 'right' as 'right',
			width: 144,
			render: (record: any) => {
				const actualPrice = record.actualPrice ? record.actualPrice : 0
				return (
					<span className='fsz-14 line-h-22 txt-color-black robotomedium'>{`${formatMoneyByUnit(
						actualPrice,
						record.currency
					)}`}</span>
				)
			},
		},
		{
			title: <span className='fsz-14 line-h-22 txt-color-gray4'>{t('shipment.quantity')}</span> ,
			key: 'quantity',
			width: 144,
			align: 'right' as 'right',
			render: (record: any) => (
				<span className='fsz-14 line-h-22 txt-color-black robotomedium'>
					{record.orderedQuantity ? record.orderedQuantity : '---'}
				</span>
			),
		},
		{
			title:  <span className='fsz-14 line-h-22 txt-color-gray4'>{t('shipment.actions')}</span>,
			key: 'actions',
			width: 144,
			align: 'right' as 'right',
			render: (record: ProductInterface) => {
				const productUpdated = {
					code: record.code,
					productUrl: record?.url,
					productImage: record?.image,
					name: record?.name,
					translatedName: record?.translatedName,
					merchantName: record?.merchantName,
					merchantContact: record?.merchantContact,
					quantity: record?.orderedQuantity,
					unitPrice: record?.actualPrice,
					shortName: record?.shortName,
					shortTranslatedName: record?.shortTranslatedName,
					unit: record?.unit,
					translatedUnit: product?.translatedUnit,
				}
				return (
					<div className='flex flex-nowrap justify-content-end align-items-center'>
						<Button
							disabled={!productUpdatable}
							onClick={() => {
								setProduct((prevState: any) => {
									setVisible(true)
									return { ...prevState, ...productUpdated }
								})
							}}
							type='text'
							className='cursor-pointer fsz-14 txt-color-orange1'>
							{t('shipment.edit')}
						</Button>
						<Divider className='vertical-divider' type='vertical' style={{height: '20px'}} />
						<Button
							disabled={!productUpdatable}
							type='text'
							className='cursor-pointer fsz-14 txt-warning'
							onClick={() => {
								setProduct(record)
								setVisibleDeleteModal(true)
							}}>
							{t('shipment.delete')}
						</Button>
					</div>
				)
			},
		},
	]

	return (
		<>
			<Table
				rowKey={(record: any) => record.id}
				columns={columns}
				dataSource={items}
				className='product-list'
				pagination={false}
				locale={{
					emptyText: (
						<EmptyDataFilter description={t('orderDetail.noItems')}/>
					),
				}}
			/>

			<CreateProductModal
				visible={visible}
				visibleHandler={(val: boolean) => setVisible(val)}
				refetch={refetch}
				type='edit'
				product={product}
			/>

			<Modal
				visible={visibleDeleteModal}
				title={t('shipment.deleteProduct')}
				closeIcon={<i className='fa-solid fa-xmark'></i>}
				onCancel={() => setVisibleDeleteModal(false)}
				okButtonProps={{
					className: 'btn-df btn-df-quaternary-bg-white fsz-14 line-h-22 py-4 px-12',
					icon: <i className='fas fa-trash mg-r-10'></i>,
					loading: loadingProductDelete,
				}}
				cancelButtonProps={{
					className: 'btn-df btn-df-tertiary-bg-white fsz-14 py-4 line-h-22 px-12',
					icon: <i className='fa-solid fa-xmark mg-r-10'></i>,
				}}
				closable
				okText={t('button.submit')}
				cancelText={t('button.cancel')}
				className='shipment-delete-product'
				onOk={() => deleteProductHandler(product?.code!)}
				centered>
				<span className='robotoregular fsz-14 line-h-22 txt-color-black'>{t('shipment.delete-confirm')}</span>
			</Modal>
		</>
	)
}
export default Products

