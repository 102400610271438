import { Avatar, Col, Divider, Row, Tooltip } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import lodash from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { formatDateTime } from '../../core/helpers/date-time'
import { formatMoneyByUnit } from '../../core/helpers/string'
import Services from '../Services'
import './styles.scss'

export interface ItemProps {
	statuses: any
	item: any
	providers: any
	idx?: number
}

function Item(props: ItemProps) {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { statuses, item, providers, idx } = props
	const [status] = useState<any>(statuses.find((x: any) => x.code === item.status))
	const [currency, setCurrency] = useState<string>()
	const [provider, setProvider] = useState<any>({})
	const refLink = useRef<HTMLDivElement | null>(null)
	const refFinance = useRef<HTMLDivElement | null>(null)
	useEffect(() => {
		const provider = providers.find((x: any) => x.code === item.providerM1)
		setProvider(provider)
		let temp = lodash.get(item, 'provider.currency', '')
		if (temp) {
			temp = JSON.parse(temp)
			setCurrency(temp.code)
		}
	}, [item, providers])
	return (
		<div className={'mg-bt-12'}>
			<Row
				key={item.id}
				className={`_order-item rad-6 flex px-16 py-12 ${idx! % 2 === 0 ? 'row-dark' : 'row-light'}`}
				onClick={() => navigate(`/orders/${item.code}`)}
				>
				<Col flex={'0 0 auto'} className='mg-r-12'>
					<Link to={`/orders/${item.code}`}>
						<Avatar
							shape={'square'}
							size={60}
							src={lodash.get(item, 'image', '')}
							className={'rad-6'}
							style={{ border: 'none' }}
						/>
					</Link>
				</Col>
				<Col flex='1 0 auto'>
					<Row align='middle' className={'mg-bt-4 flex-nowrap'}>
						<Col flex={'0 0 250px'} className='flex align-items-center'>
							<span className={'align-items-center txt-color-primary mg-r-16'}>
								<Link to={`/orders/${item.code}`}>
									<Paragraph
										className='robotomedium mg-0 txt-color-orange2 fsz-14 line-h-22 order-heading whitespace'
										copyable={{
											text: item.code,
											icon: [
												<Tooltip
													title={
														<span className='robotoregular fsz-12 txt-color-black line-h-20'>
															Copy
														</span>
													}
													color='#FBB739'>
													<i className='far fa-copy line-h-14 w-12'></i>
												</Tooltip>,
												<Tooltip
													title={
														<span className='robotoregular fsz-12 txt-color-black line-h-20'>
															Copied
														</span>
													}
													color='#FBB739'>
													<i className='far fa-check fsz-12 line-h-14 w-12'></i>
												</Tooltip>,
											],
											tooltips: [false, false],
										}}>
										{item.code ? item.code : '---'}
									</Paragraph>
								</Link>
							</span>
							<span className={'fsz-14 robotoregular txt-color-black flex align-items-center'}>
								<Tooltip
									className={'mgl4 txt-size-h9'}
									title={
										<span className={'txt-color-white pd-12 fsz-12 line-h-20 robotomedium'}>
											{t('orders.tooltipQuantity')}
										</span>
									}
									color={'#F9A400'}>
									<span className='fsz-14 robotoregular'>{`${lodash.get(item, 'orderedQuantity', '--')}/${
										item.purchasedQuantity !== null ? item.purchasedQuantity : '--'
									}/${item.receivedQuantity != null ? item.receivedQuantity : '--'}`}</span>
								</Tooltip>
							</span>
						</Col>
						<Col flex={'0 0 250px'} className={'flex align-items-center'}>
							<span className={'fsz-14 line-h-22 robotoregular whitespace'}>
								{t('orders.finance')}:
								<Tooltip
									className={'mgl2 txt-size-h9'}
									color={'#F9A400'}
									title={
										<span className={'txt-color-white pd-10 fsz-12 line-h-20 robotomedium'}>
											{t('orders.tooltipFinance')}
										</span>
									}><span className={'txt-color-orange2 robotomedium mgl3 fsz-14 line-h-22'}>{`${formatMoneyByUnit(
										lodash.get(item, 'provisionalAmount', ''),
										currency
									)}/${
										item.status !== 'CANCELLED'
											? formatMoneyByUnit(lodash.get(item, 'totalUnpaid', ''), currency)
											: t('orders.cancelled')
									}`}</span></Tooltip>
								
							</span>
						</Col>

						<Col flex={'1 1 auto'} className='flex align-items-start'>
							<span className={'fsz-14 line-h-22 txt-color-secondary mgr3 whitespace'}>
								{t('orders.services')}:
							</span>
							<Services
								data={item.services}
								className='fsz-14 line-h-22'
								dividerClass='service-divider-black'
								wrapperClass=''
							/>
						</Col>
					</Row>

					<Row className='flex justify-content-start'>
						<Col flex={'0 0 250px'} className={'flex align-items-center '}>
							<Tooltip
								title={
									<span className={'robotoregular fsz-14 line-h-22 txt-color-white'}>{t('orders.note')}</span>
								}
								className={'txt-color-black txt-size-h7'}
								color={'#F9A400'}>
								<i className='fal fa-file-alt txt-size-h8 txt-color-secondary mgr3 mg-minus-2' />
							</Tooltip>
							<Tooltip
								title={
									<span className={'robotoregular fsz-14 line-h-22 txt-color-white'}>{item.personalNote ? item.personalNote : '--'}</span>
								}
								className={'txt-color-black txt-size-h7'}
								color={'#F9A400'}>
								
								<span className={'fsz-14 line-h-22 txt-color-black personal-note  w-200'}>
									{item.personalNote ? item.personalNote : '--'}
								</span>								
							</Tooltip>
							{/* {item.personalNote && item.personalNote.length > 30 ? (
								<div className={'flex align-items-center flex-nowrap'}>
									<span className={'fsz-14 line-h-22 txt-color-black personal-note w-200'}>
										{item.personalNote ? item.personalNote : '--'}
									</span>
									<Tooltip
										title={
											<span className={'robotoregular txt-size-h8 txt-color-black'}>
												{item.personalNote}
											</span>
										}
										className={'txt-size-h9 robotoregular txt-color-white'}
										color={'#F9A400'}>
										<span></span>
									</Tooltip>
								</div>
							) : (
								
							)} */}
						</Col>

						<Col flex={'0 0 250px'} className={'align-items-center flex'}>
							<Avatar
								size={16}
								src={lodash.get(provider, 'logo', '')}
								shape={'circle'}
								style={{ border: 'none' }}></Avatar>
							<span className={'mgl5 fsz-14 line-h-22 robotoregular txt-color-black'}>
								{lodash.get(provider, 'code', '')}
							</span>
							<span className={'txt-color-black mgl5 mgr5'}>|</span>
							<span className={'fsz-14 line-h-22 robotoregular txt-color-black'}>
								{lodash.get(item, 'customer', '')}
							</span>
						</Col>
						<Col flex={'1 1 auto'} className={'fsz-14 line-h-22 robotoregular txt-color-gray4'}>
							<Tooltip title={<span className='txt-color-white'>{t('orders.tooltipCreatedAt')}</span>} 	color='#FBB739'>
								{formatDateTime(lodash.get(item, 'createdAt', '--'))}
							</Tooltip>
						</Col>
					</Row>
				</Col>
				<Col className='flex align-items-center justify-content-end'>
					<span
						style={{ backgroundColor: lodash.get(status, 'color', '#09B8AF') }}
						className={'rad-25 item-align-center txt-color-white fsz-14 line-h-22 robotomedium px-16 py-4'}>
						{lodash.get(status, 'name', '--')}
					</span>
				</Col>
			</Row>
		</div>
	)
}

export default Item

