import { Button, Form, Input } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormValues } from '.'
import { formatMoneyByUnit } from '../../../core/helpers/string'
import DraftShipmentInterface, { FeeInterfaceInDraftShipment } from '../../../Interface/DraftShipmentInterface'
import ServiceInterface from '../../../Interface/ServiceInterface'
const { Item } = Form
interface ShipmentFeesAndFormProps {
	services: ServiceInterface[]
	draftShipment?: DraftShipmentInterface
	loading: boolean
	calculating: boolean
	allowCreate: boolean
	currency?: string
	currentServiceHasError: any
	checkedList: any
	submitHandler: (formObj: FormValues) => void
}
const ShipmentFeesAndForm: React.FC<ShipmentFeesAndFormProps> = (props) => {
	const { t } = useTranslation()
	const [form] = Form.useForm()
	const { submitHandler, draftShipment, loading, calculating, allowCreate, currency } = props
	const [expectedPackages, setExpectedPackages] = useState('')

	const onFinish = () => {
		submitHandler({...form.getFieldsValue(true), expectedPackages: Number(expectedPackages)})
	}
	return (
		<div className='box box-fees'>
			<div className='flex flex-col'>
				<div className='flex flex-col bg-color-pink2 pd-8 mg-bt-12'>
					<div className='flex align-items-center'>
						<span className='robotomedium txt-color-black fsz-16 line-h-24 whitespace mg-bt-4 mg-r-12'>
							{t('shipment.calculateFeesTemp')}
						</span>
						<span className='box-divider box-divider-orange'></span>
					</div>
					{draftShipment?.fees && (
						<div className='box-fees__details'>
							{draftShipment?.fees?.map((fee: FeeInterfaceInDraftShipment) => (
								<div key={fee.id} className='flex align-items-center justify-content-between'>
									<span className='label'>{t(`shipments.${fee.feeType}`)}</span>
									<span className='value'>
										{fee.provisionalAmount ? formatMoneyByUnit(fee.provisionalAmount, currency) : '---'}
									</span>
								</div>
							))}
							<div className='flex align-items-center justify-content-between'>
								<span className='label'>{t(`shipments.totalFeesTemp`)}</span>
								<span className='value'>
									{draftShipment?.totalFee ? formatMoneyByUnit(draftShipment?.totalFee, currency) : '---'}
								</span>
							</div>
						</div>
					)}
				</div>

				<div className='flex align-items-center px-8 mg-bt-12'>
					<span className='robotomedium txt-color-black fsz-16 line-h-24 whitespace mg-r-12'>{t('shipment.shipmentInfo')}</span>
					<span className='box-divider box-divider-orange'></span>
				</div>
				<div className='box-fees__shipment-info px-8'>
					<Form
						name='form-shipment'
						// labelCol={{ span: 10 }}
						// wrapperCol={{ span: 14 }}
						initialValues={{ refCustomerCode: '', refShipmentCode: '', expectedPackages: '' }}
						onFinish={onFinish}
						autoComplete='off'
						className='form-shipment'
						form={form}>
						<Item
							label={`${t('shipment.packages')}`}
							// name='expectedPackages'
							labelAlign='left'
							colon={false}>
							<Input
								placeholder={t('shipment.packagesPlaceholder')}
								pattern={'^[0-9\b]+$'}
								value={expectedPackages}
								onChange={(e) => setExpectedPackages((v) => (e.target.validity.valid ? e.target.value : v))}
							/>
						</Item>
						<Item label={`${t('shipment.orderCode')}`} labelAlign='left' name='refShipmentCode' colon={false}>
							<Input placeholder={t('shipment.orderCodePlaceholder')} />
						</Item>

						<Item label={`${t('shipment.customerCode')}`} labelAlign='left' name='refCustomerCode' colon={false}>
							<Input placeholder={t('shipments.customerCodePlaceholder')} />
						</Item>
					</Form>
				</div>
			</div>
			<Button
				className='btn-df btn-df-secondary-bg-blue robotomedium flex align-items-center flex-nowrap justify-content-center fsz-14 line-h-22'
				htmlType='submit'
				form='form-shipment'
				icon={<i className='mg-r-10 fa-solid fa-plus'></i>}
				loading={loading}
				disabled={!allowCreate || calculating}>
				{t('shipment.createShipment')}
			</Button>
		</div>
	)
}

export default ShipmentFeesAndForm
