import { Upload } from 'antd';
import { DragableUploadListItem } from './DragableIUploadListItem';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { UploadFile } from 'antd/lib/upload/interface';

import { UploadCommonProps } from '.';

import './style.scss';

interface DragableUploadProps extends UploadCommonProps {}

const DragableUpload: React.FC<DragableUploadProps> = ({
  setFileList = () => {},
  ...props
}: DragableUploadProps) => {
  const { fileList = [] } = props;
  const onRemove = (file: UploadFile<any>): void => {
    const result: UploadFile<unknown>[] = removeItem(
      fileList,
      Number(file.uid)
    );
    setFileList(result);
  };

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const itemsTemp = reorder(
      fileList,
      result.source.index,
      result.destination.index
    );

    setFileList(itemsTemp);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal" mode="standard">
        {(provided: any, snapshot: any) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <Upload
              onRemove={onRemove}
              {...props}
              itemRender={(originNode: any, file, currFileList) => {
                return (
                  <DragableUploadListItem
                    originNode={originNode}
                    file={file}
                    fileList={currFileList}
                  />
                );
              }}
            />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export { DragableUpload };

const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result.map((row: any, uid: number) => ({ ...row, uid: String(uid) }));
};

const removeItem = (list: any, startIndex: number) => {
  const result = Array.from(list);
  result.splice(startIndex, 1);

  return result.map((row: any, uid: number) => ({ ...row, uid: String(uid) }));
};
