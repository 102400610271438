import { Col, Modal, notification, Row, Table } from 'antd'
import lodash, { map, sortBy } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import EmptyDataFilter from '../../../../../components/Empty/EmptyDataFilter'
import { SkeletonTable, SkeletonTableColumnsType } from '../../../../../components/SkeletonTable'
import { formatDateTime } from '../../../../../core/helpers/date-time'
import { formatNumber } from '../../../../../core/helpers/string'
import { WayBillInterface } from '../../../../../Interface/ShipmentInterface'
import { categoryRepository } from '../../../../../repositories/CategoryRepository'
import { shipmentRepository } from '../../../../../repositories/ShipmentRepository'
import TracksPackage from './TrackPackage'
interface OrderItemsProps {
	packages: any
	loading: boolean
	waybills?: WayBillInterface[]
	shipment: any
	shipmentStatuses: any
	refetch: () => void
	waybillFromShipment: any
	fetchWaybills: () => void
}

const Packages: React.FC<OrderItemsProps> = (props) => {
	const [statuses, setStatuses] = useState([])
	const { packages, loading, waybills, refetch, shipment, shipmentStatuses, waybillFromShipment, fetchWaybills } = props
	const { t } = useTranslation()
	const { code } = useParams()
	const [packageTransform, setPackageTransform] = useState<any>([])
	// const [expand, setexpand] = useState();
	const [expandedRows, setExpandedRows] = useState<Array<any>>([])
	const [visibleDeleteWaybill, setVisibleDeleteWaybill] = useState(false)
	const [loadingDeleteWaybill, setLoadingDeleteWaybill] = useState(false)
	const [curWaybillCode, setCurWaybillCode] = useState('');
	
	const getStatuses = useCallback(async () => {
		try {
			const res = await categoryRepository.getShipmentPackageStatuses()
			setStatuses(res)
		} catch (err) {
			console.log(err)
		}
	}, [])





	useEffect(() => {
		getStatuses()
	}, [getStatuses])

	useEffect(() => {
		let packageTransform = []
		if (waybillFromShipment && waybillFromShipment.length) {
			let waybillCodes = map(sortBy(waybillFromShipment, ['createdAt']).reverse(), 'code')
			for (const waybillCode of waybillCodes) {
				const curWaybill = waybillFromShipment.find((waybill: any) => waybill.code === waybillCode)
				const packageGrouped = packages.filter((pack: any) => pack.waybillCode === waybillCode)
				const packageUpdated = {
					packages: packageGrouped,
					total: packageGrouped?.length,
					waybillCode,
					createdAt: curWaybill?.createdAt,
				}
				packageTransform.push(packageUpdated)
			}
		}
		setPackageTransform(packageTransform)
	}, [packages, waybillFromShipment, waybills])

	const deleteWaybillHandler = useCallback(
		async (waybillCode: string) => {
			setLoadingDeleteWaybill(true)
			try {
				await shipmentRepository.deleteWaybill(code!, waybillCode)
				setLoadingDeleteWaybill(false)
				setVisibleDeleteWaybill(false)
				notification.success({message: t('shipment.deleteWaybillSucceed')})
				refetch()
				fetchWaybills()
			} catch (err) {
				setLoadingDeleteWaybill(false)
			}
		},
		[code, fetchWaybills, refetch, t]
	)

	const packageInfoText = 'robotomedium fsz-12 line-h-20 whitespace'
	const columnsPackages = [
		{
			title: <span className='pd-12 pd-l-40 txt-color-blue6 fsz-12'>{t('package.code')}</span>,
			key: 'code',
			dataIndex: 'code',
			width: '14%',
			render: (code: string) => <span className={`${packageInfoText} txt-color-blue7 pd-l-28`}>{code}</span>,
		},
		{
			title: (
				<div className='pd-l-12'>
					<span className={`mg-r-4 ${packageInfoText} txt-color-blue6`}>{t('package.weight')}</span>
					<span className={`txt-color-gray4 robotoregular fsz-12 line-h-20 whitespace`}>
						{t('package.noteForWeight')}
					</span>
				</div>
			),
			key: 'info',
			width: '28%',
			render: (record: any) => {
				const emptyTxt = '---'
				const actualWeight = lodash.isNull(lodash.get(record, 'actualWeight'))
					? emptyTxt
					: lodash.get(record, 'actualWeight', emptyTxt)
				const netWeight = lodash.isNull(lodash.get(record, 'netWeight'))
					? emptyTxt
					: lodash.get(record, 'netWeight', emptyTxt)
				const packagingWeight = lodash.isNull(lodash.get(record, 'packagingWeight'))
					? emptyTxt
					: lodash.get(record, 'packagingWeight', emptyTxt)
				const dimensionalWeight = lodash.isNull(lodash.get(record, 'dimensionalWeight'))
					? emptyTxt
					: lodash.get(record, 'dimensionalWeight', emptyTxt)
				return (
					<span className={`${packageInfoText} pd-l-12`}>{`${formatNumber(actualWeight)}kg / ${formatNumber(
						netWeight
					)}kg / ${formatNumber(packagingWeight)} kg / ${formatNumber(dimensionalWeight)}kg`}</span>
				)
			},
		},
		{
			title: (
				<>
					<span className='mg-r-4 txt-color-blue6 fsz-12 line-h-20'>{t('package.details')}</span>
					<span className='txt-color-blue6 txt-color-blue6 fsz-12 line-h-20'>{t('package.noteForDetail')}</span>
				</>
			),
			key: 'details',
			width: '12%',
			render: (record: any) => {
				const emptyTxt = '---'
				const length = lodash.isNull(lodash.get(record, 'length')) ? emptyTxt : lodash.get(record, 'length', emptyTxt)
				const width = lodash.isNull(lodash.get(record, 'width')) ? emptyTxt : lodash.get(record, 'width', emptyTxt)
				const height = lodash.isNull(lodash.get(record, 'height')) ? emptyTxt : lodash.get(record, 'height', emptyTxt)
				return (
					<span className={packageInfoText}>{`${formatNumber(length)} x ${formatNumber(width)} x ${formatNumber(
						height
					)} (cm)`}</span>
				)
			},
		},
		{
			title: <span className='fsz-12 line-h-20 txt-color-blue6'>{t('package.status')}</span>,
			key: 'notice',
			dataIndex: 'status',
			width: '14%',
			render: (status: string) => {
				const statusObj = statuses.find(({ code }) => code === status)
				const statusName = lodash.get(statusObj, 'name', '---')

				return (
					<span
						className={`robotoregular fsz-12 line-h-20 whitespace txt-color-white border-radius25 py-3 px-12`}
						style={{
							backgroundColor: lodash.isNull(lodash.get(statusObj, 'color'))
								? '#09B2AA'
								: lodash.get(statusObj, 'color'),
						}}>
						{statusName}
					</span>
				)
			},
		},
		{
			title:  <span className='fsz-12 line-h-20 txt-color-blue6'>{t('package.update')}</span>,
			key: 'notice',
			dataIndex: 'lastStatusTime',
			render: (lastStatusTime: any) => (
				<Row justify='space-between' align='middle'>
					<Col>
						<span className={packageInfoText}>
							{lodash.isNull(lastStatusTime) ? t('orderDetail.undefined') : formatDateTime(lastStatusTime)}
						</span>
					</Col>
				</Row>
			),
		},
	]

	const columns = [
		{
			title: <span className='fsz-14 line-h-22 txt-color-gray4'>{`${t('shipment.packageWaybillCode')} (${packageTransform.length})`}</span>,
			key: 'code',
			// width: '30%',
			dataIndex: 'waybillCode',
			render: (waybillCode: string) => (
				<span className='txt-color-orange1 robotoregular fsz-14 linne-h-22'>{waybillCode}</span>
			),
		},
		{
			title: <span className='fsz-14 line-h-22 txt-color-gray4'>{t('shipment.totalPackages')}</span> ,
			key: 'total',
			// width: '30%',
			dataIndex: 'total',
			align: 'right' as 'right',
			render: (total: number) => (
				<span className='txt-color-black robotoregular fsz-14 line-h-22'>{formatNumber(total)}</span>
			),
		},
		{
			title: <span className='fsz-14 line-h-22 txt-color-gray4'>{t('shipment.createdAtWaybill')}</span>,
			dataIndex: 'createdAt',
			render: (createdAt: any, record: any) => {
				const shipmentStatus = shipmentStatuses.find((stt: any) => stt.code === shipment?.status)
				const shipmentStatusWithCodeMerchantDelivering = shipmentStatuses.find((stt: any) => stt.code === 'MERCHANT_DELIVERING')
				return (
					<div className='flex justify-content-between'>
						<span className='fsz-14 line-h-22 txt-color-black robotoregular'>
							{createdAt ? formatDateTime(createdAt) : 'Chưa xác định'}
						</span>
						{!record.packages.length  && shipmentStatus?.position <= shipmentStatusWithCodeMerchantDelivering?.position  && <i
							className='fa-solid fa-trash txt-color-gray4'
							onClick={(e: any) => {
								e.stopPropagation()
								setVisibleDeleteWaybill(true)
								setCurWaybillCode(record.waybillCode)
							}}></i>}
					</div>
				)
			},
		},
	]

	const localeCustom = {
		emptyText: (
			<>
				{!props.loading ? (
					<EmptyDataFilter description={t('shipment.waybillsEmpty')} className='' />
				) : (
					<div className='dpl-none'></div>
				)}
			</>
		),
	}

	const total = packages.length
	return (
		<>
			<SkeletonTable
				columns={columns as SkeletonTableColumnsType[]}
				rowCount={3}
				loading={loading}
				className={`package-list`}>
				<Table
					rowKey={(record: any) => record.waybillCode}
					columns={columns}
					dataSource={packageTransform}
					locale={localeCustom}
					loading={loading}
					className={`package-list table-expandable table-expandable_has-milestone`}
					pagination={false}
					// scroll={{ x: 500 }}
					// size='small'
					expandable={{
						columnWidth: 0,
						expandRowByClick: true,
						expandedRowRender: (record, index) => {
							const components = { body: { row: TracksPackage } }
							return record.packages.length > 0 ? (
								<Table
									components={components}
									rowKey={(record: any) => record.id}
									columns={columnsPackages}
									dataSource={record.packages}
									locale={localeCustom}
									loading={loading}
									className={`package-list package-list-waybill`}
									pagination={false}
									// scroll={{ x: 500 }}
									size='small'
									onRow={(record, index) => {
										return { ...record, total, index, statuses }
									}}
								/>
							) : (
								<EmptyDataFilter description={t('packages.empty')} />
							)
						},
						expandIcon: ({ expanded, onExpand, record }) =>
							expanded ? (
								<i
									className='far fa-angle-down txt-color-orange2 w-8 line-h-22 cursor-pointer'
									onClick={(e) => onExpand(record, e)}></i>
							) : (
								<i
									className='far fa-angle-right txt-color-orange2 w-8 line-h-22 cursor-pointer'
									onClick={(e) => onExpand(record, e)}></i>
							),
						onExpandedRowsChange: (expandedRows) => {
							setExpandedRows([...expandedRows])
						},
					}}
					rowClassName={(record) =>
						lodash.includes(expandedRows, record.waybillCode)
							? 'active-row cursor-pointer bg-color-orange5'
							: 'data-row cursor-pointer'
					}
				/>
			</SkeletonTable>
			<Modal
				title={<span className='fsz-16 line-h-24 txt-color-black robotomedium'>{t('shipment.deleteWaybill')}</span>}
				closeIcon={<i className='fa-solid fa-xmark'></i>}
				centered
				onCancel={() => setVisibleDeleteWaybill(false)}
				onOk={() => deleteWaybillHandler(curWaybillCode)}
				okText={t('button.submit')}
				cancelText={t('button.cancel')}
				confirmLoading={loadingDeleteWaybill}
				okButtonProps={{
					className: 'btn-df btn-df-quaternary-bg-white fsz-14 line-h-22 py-4 px-12',
					icon: <i className='fas fa-trash mg-r-10'></i>,
					loading: loadingDeleteWaybill,
				}}
				cancelButtonProps={{
					className: 'btn-df btn-df-tertiary-bg-white fsz-14 py-4 line-h-22 px-12',
					icon: <i className='fa-solid fa-xmark mg-r-10'></i>,
				}}
				visible={visibleDeleteWaybill}>
				<span className='fsz-14 line-h-22 txt-color-black robotoregular'>{t('shipment.deleteWaybillConfirm')}</span>
			</Modal>
		</>
	)
}
export default Packages

