import { Avatar, Table } from 'antd'
import lodash from 'lodash'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'
import { formatDateTime } from '../../core/helpers/date-time'
import { formatMoneyByUnit } from '../../core/helpers/string'
import ThumbClaim from '../../resources/images/claim-thumb.png'
import { getConnectionByNameAndProviderCode, getConnectionFromConnections } from '../../utils/util'
import Clipboard from '../Clipboard'
import EmptyDataFilter from '../Empty/EmptyDataFilter'
import { Provider } from '../Provider'
import { SkeletonTable, SkeletonTableColumnsType } from '../SkeletonTable'
type ClaimListProps = {
	ticketStatuses: any[]
	providerAccounts: any[]
	claimData: any
	loadingClaim: boolean
	isSkeleton: boolean
}

function ClaimList(props: ClaimListProps) {
	const { t } = useTranslation()
	const [searchParams, setSearchParams] = useSearchParams()
	const { providerAccounts, claimData, loadingClaim, isSkeleton } = props

	const column = [
		{
			title: (
				<span className={'fsz-14 line-h-22 robotomedium txt-color-gray4 dpl-block width80px'}>{t('tickets.ticket-code')}</span>
			),
			dataIndex: 'code',
			className: 'fsz-14 line-h-22 robotomedium txt-color-gray4',
			key: 'code',
			width: 94,
			render: (code: any, row: any) => {
				const connection = getConnectionFromConnections(providerAccounts, lodash.get(row, 'owner.username'), row.tenant)
				return (
					<span className={'align-items-center txt-color-primary'}>
						<Link
							to={`/claims/${lodash.get(connection, 'provider.code')}/${lodash.get(
								connection,
								'name'
							)}/${lodash.get(row, 'relatedOrder')}/${lodash.get(row, 'code')}/claims`}>
							<span className={'txt-size-h7 robotomedium txt-color-primary'}>#{code}</span>
						</Link>
						{code && <Clipboard value={code} />}
					</span>
				)
			},
		},
		{
			title: <span className={'fsz-14 line-h-22 robotomedium txt-color-gray4'}>{t('tickets.ticket-name')}</span>,
			dataIndex: 'name',
			key: 'name',
			className: 'fsz-14 line-h-22 robotomedium txt-color-gray4',
			width: 288,
			render: (name: any, record: any) => {
				return (
					<div className={'align-items-center'}>
						<Avatar
							src={record.thumbnail ?? ThumbClaim}
							size={36}
							shape={'square'}
							className={'bd-none-impt border-radius4'}
						/>
						<span className={'txt-size-h7 robotomedium txt-color-black mgl5'}>{name}</span>
					</div>
				)
			},
		},
		{
			title: (
				<span className={'fsz-14 line-h-22 robotomedium txt-color-gray4 dpl-block width120px'}>
					{t('tickets.provider-account')}
				</span>
			),
			dataIndex: 'owner',
			className: 'fsz-14 line-h-22 robotomedium txt-color-gray4',
			key: 'owner',
			width: 212,
			render: (owner: any, row: any) => {
				let connection = getConnectionByNameAndProviderCode(
					providerAccounts,
					lodash.get(owner, 'username'),
					lodash.get(row, 'tenant')
				)
				return <Provider provider={connection.provider} customerUsername={connection.name} className='claim-provider' />
			},
		},
		{
			title: <span className={'fsz-14 line-h-22 robotomedium txt-color-gray4'}>{t('tickets.ticket-created-at')}</span>,
			dataIndex: 'createdAt',
			key: 'createdAt',
			className: 'fsz-14 line-h-22 robotomedium txt-color-gray4',
			width: 140,
			render: (createdAt: any) => (
				<span className={'robotoregular txt-size-h7 txt-color-black'}>{formatDateTime(createdAt)}</span>
			),
		},
		{
			title: (
				<span className={'fsz-14 line-h-22 robotomedium txt-color-gray4 dpl-block width120px'}>
					{t('tickets.ticket-status')}
				</span>
			),
			key: 'state',
			className: 'fsz-14 line-h-22 robotomedium txt-color-gray4',
			width: 136,
			render: (row: any) => {
				return (
					<>
						<span
							className={`ticket-status txt-size-h8 robotoregular txt-capitalize whitespace`}
							style={{ backgroundColor: `${lodash.get(row, 'publicState.color')}` }}>
							{lodash.get(row, 'publicState.name')}
							<span>{lodash.get(row, 'archived') === true ? ` (${t('tickets.archived')})` : ''}</span>
						</span>
					</>
				)
			},
		},
		{
			title: <span className={'fsz-14 line-h-22 robotomedium txt-color-gray4 flr'}>{t('tickets.refund-amount')}</span>,
			dataIndex: 'estimatedRefundValue',
			key: 'estimatedRefundValue',
			className: 'fsz-14 line-h-22 robotomedium txt-color-gray4',
			width: 120,
			align: 'right' as 'right',
			render: (estimatedRefundValue: any, record: any) => (
				<span
					className={`${
						estimatedRefundValue ? 'ticket-refund flr txt-size-h7 robotoregular' : 'flr txt-size-h7 robotoregular'
					}`}>
					{record.state === 'REFUND' ? formatMoneyByUnit(estimatedRefundValue) : '---'}
				</span>
			),
		},
		{
			title: <span className={'fsz-14 line-h-22 robotomedium txt-color-gray4'}>{t('tickets.resolve-methods')}</span>,
			dataIndex: 'solution',
			className: 'fsz-14 line-h-22 robotomedium txt-color-gray4',
			key: 'solution',
			width: 136,
			render: (solution: any) => (
				<span className={'txt-size-h7 txt-color-black-robotoregular fll'}>{solution ? solution.name : ''}</span>
			),
		},
	]
	const customLocale = {
		emptyText: (
			<EmptyDataFilter
				className={loadingClaim || isSkeleton ? 'dpl-none' : ''}
				description={
					!searchParams.get('publicState') &&
					!searchParams.get('solutionCode') &&
					!searchParams.get('code') &&
					!searchParams.get('ticketType')
					&& !searchParams.get('providerCode') && !searchParams.get('accountCode')
						? t('tickets.empty-list')
						: t('claims.filterEmpty')
				}
			/>
		),
	}

	return (
		<div className={'ticket-list mgt15 align-items-center'}>
			{console.log('loadingClaim', loadingClaim, 'isSkeleton', isSkeleton)}
			<SkeletonTable
				loading={loadingClaim || isSkeleton}
				rowCount={claimData?.length}
				columns={column as SkeletonTableColumnsType[]}
				className='ticket-list  main-table'>
				<Table
					className={'ticket-list main-table'}
					columns={column}
					dataSource={claimData}
					pagination={false}
					rowKey={record => record.id}
					rowClassName={'custom-row-hover'}
					locale={customLocale}
				/>
				
			</SkeletonTable>
		</div>
	)
}

export default ClaimList

