import React from 'react';
import Paragraph from 'antd/lib/typography/Paragraph';
import { Tooltip } from 'antd';

import './style.scss';

interface ParagraphWithCopyProps {
  children: any;
  text: string;
}

const ParagraphWithCopy: React.FC<ParagraphWithCopyProps> = ({
  children,
  text,
}: ParagraphWithCopyProps): JSX.Element => {
  return (
    <Paragraph
      copyable={{
        text,
        icon: [
          <Tooltip
            title={
              <span className="robotoregular fsz-12 txt-color-black line-h-20">
                Copy
              </span>
            }
            color="#FBB739"
          >
            <i className="far fa-copy line-h-14 w-12"></i>
          </Tooltip>,
          <Tooltip
            title={
              <span className="robotoregular fsz-12 txt-color-black line-h-20">
                Copied
              </span>
            }
            color="#FBB739"
          >
            <i className="far fa-check fsz-12 line-h-14 w-12"></i>
          </Tooltip>,
        ],
        tooltips: [false, false],
      }}
      className="text-copy"
    >
      {children}
    </Paragraph>
  );
};

export default ParagraphWithCopy;
