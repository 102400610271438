import lodash from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import MainLayout from '../../components/Layout'
import { PACKAGES_DESCRIPTIONS } from '../../core/config'
import { categoryRepository } from '../../repositories/CategoryRepository'
import { packageRepository } from '../../repositories/PackagesRepository'
import { supplierRepository } from '../../repositories/SupplierRepository'
import M24Notification from '../../utils/M24Notification'
import { getCleanFormFilter } from '../../utils/StringUtils'
import Filter from './Filter'
import List from './List'

const Packages = () => {
	const [showProgressBar, setShowProgressBar] = useState(false)
	const [searchParams, setSearchParams] = useSearchParams()
	const [accounts, setAccounts] = useState([])
	const [statuses, setStatuses] = useState([])
	const [packages, setPackages] = useState([])

	const [size, setSize] = useState(Number(searchParams.get('size')))
	const [page, setPage] = useState(Number(searchParams.get('page')))
	const [total, setTotal] = useState(0)

	const [idAcc, setIdAcc] = useState('')
	const [filter, setFilter] = useState<any>({})
	const { t } = useTranslation()

	const getData = useCallback(() => {
		const customer = searchParams.get('customer') && searchParams.get('customer')
		const provider = searchParams.get('provider') && searchParams.get('provider')
		const statuses = searchParams.get('statuses')
		const query = searchParams.get('query')
		const createdFrom = searchParams.get('createdFrom')
		const createdTo = searchParams.get('createdTo')
		const cutOffStatus = searchParams.get('cutOffStatus')
		const typeSearch = searchParams.get('typeSearch')
		const handlingTimeFrom = searchParams.get('handlingTimeFrom')
		const handlingTimeTo = searchParams.get('handlingTimeTo')
		const page = searchParams.get('page')
		const size = searchParams.get('size')

		const params = {
			statuses,
			customer,
			provider,
			query,
			createdFrom,
			createdTo,
			cutOffStatus,
			typeSearch,
			handlingTimeFrom,
			handlingTimeTo,
			page,
			size,
		}
		setFilter((prevState: any) => ({ ...prevState, ...getCleanFormFilter(params) }))
		const controller = new AbortController()
		const { signal } = controller
		if (provider && customer) {
			packageRepository
				.getPackages({ ...getCleanFormFilter(params) }, signal)
				.then((response: any) => {
					const currentPage = lodash.get(response, 'headers.x-page-number')
					const total = lodash.get(response, 'headers.x-total-count')
					setPackages(response.data)
					setPage(parseInt(currentPage) + 1)
					setTotal(parseInt(total))
					setSize(parseInt(lodash.get(response, 'headers.x-page-size')))
				})
				.catch((err) => {
					M24Notification.messageError(t(err), '', 3)
				})
				.finally(() => setShowProgressBar(false))
		}
	}, [searchParams, t])


	const getAccounts = useCallback(() => {
		supplierRepository
			.getListAccount()
			.then((res) => {
				const accounts = res.filter(({ status }: any) => status === 'CONNECTED')
				if (accounts && accounts.length > 0) {
					const indexOfAccount = accounts.findIndex((acc: any) => acc.defaultConnection)
					const account = indexOfAccount > -1 ? accounts[indexOfAccount] : accounts[0]
					setIdAcc(lodash.get(account, 'id'))
				}
				setAccounts(accounts)
			})
			.catch((err) => {})
	}, [])

	const getStatuses = useCallback(() => {
		categoryRepository
			.getPackageStatuses()
			.then((res) => {
				setStatuses(res)
			})
			.catch((err) => {})
	}, [])

	useEffect(() => {
		getStatuses()
		getAccounts()
	}, [getAccounts, getStatuses])

	useEffect(() => {
		const controller = new AbortController()
		getData()
		return () => {
			controller.abort()
		}
	}, [getData])

	const handleFilter = (params: any) => {
		setFilter({ ...params })
		setSearchParams({ ...params })
	}

	const handleProgressBar = (val: boolean) => {
		setShowProgressBar(val)
	}

	const filterChangeHandler = (obj: any) => {
		setFilter((prevState: any) => setFilter({ ...prevState, ...obj }))
	}


	return (
		<MainLayout
			title={t('menu.packages')}
			showProgressBar={showProgressBar}
			descriptions={{
				title: t('packages.pageTitle'),
				descriptions: t('packages.descriptions'),
				className: 'box-shadow-descriptions',
				pageCode: PACKAGES_DESCRIPTIONS,
				hasDescription: true,
			}}
			hasExtensionCommon={false}
			breadcrumbs={{total}}>
			<Filter
				accounts={accounts}
				idAcc={idAcc}
				statuses={statuses}
				filter={filter}
				handleAcc={(id: string) => setIdAcc(id)}
				handleFilter={handleFilter}
				handleProgressBar={handleProgressBar}
				filterChangeHandler={filterChangeHandler}
				showProgressBar={showProgressBar}
			/>
			<List
				filter={filter}
				idAcc={idAcc}
				packages={packages}
				statuses={statuses}
				accounts={accounts}
				handleFilter={handleFilter}
				total={total}
				page={page}
				size={size}
				showProgressBar={showProgressBar}
			/>
		</MainLayout>
	)
}

export default Packages

