import { Button, Empty, Image, notification } from 'antd'
import lodash from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import MainLayout from '../../../components/Layout'
import Address from '../../../components/ShoppingCart/Address/AddressList'
import CreateAddressModal from '../../../components/ShoppingCart/Address/CreateAddressModal'
import Services from '../../../components/ShoppingCart/Services'
import { TENANT_KEY } from '../../../core/config'
import AccountBalanceInterface from '../../../Interface/AccountBalanceInterface'
import DeliveryAddressInterface from '../../../Interface/DeliveryAddressInterface'
import DraftShipmentInterface from '../../../Interface/DraftShipmentInterface'
import ServiceGroupedInterface from '../../../Interface/ServiceGroupedInterface'
import ServiceInterface from '../../../Interface/ServiceInterface'
import { cartRepository } from '../../../repositories/CartRepository'
import { shipmentRepository } from '../../../repositories/ShipmentRepository'
import { supplierRepository } from '../../../repositories/SupplierRepository'
import EmptyImage from '../../../resources/images/empty-2.png'
import { localStorageRead } from '../../../utils/LocalStorageUtils'
import M24ErrorUtils from '../../../utils/M24ErrorUtils'
import M24Notification from '../../../utils/M24Notification'
import AddressInfo from './AddressInfo'
import ConnectionSelection from './ConnectionSelection'
import './create.styles.scss'
import Note from './Note'
import ShipmentFeesAndForm from './ShipmentFeesAndForm'
export interface FormValues {
	refCustomerCode: string
	expectedPackages: number
	refShipmentCode: string
}

interface CreateShipmentProps {}
const CreateShipment: React.FC<CreateShipmentProps> = (props) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [openModalAdd, setOpenModalAdd] = useState<boolean>(false)
	const [deliveryAddresses, setDeliveryAddresses] = useState<DeliveryAddressInterface[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [deliveryAddress, setDeliveryAddress] = useState<DeliveryAddressInterface>()
	const [selectedAddress, setSelectedAddress] = useState<any>()
	const [noticeWarehouse, setNoticeWarehouse] = useState('')
	const [personalNote, setPersonalNote] = useState('')
	const [employeeNote, setEmployeeNote] = useState('')
	const [noticeOneTime, setNoticeOneTime] = useState(true)
	const [accountDefault, setAccountDefault] = useState<AccountBalanceInterface>()
	const [accountBalance, setAccountBalance] = useState<AccountBalanceInterface[]>([])
	const [currentConfigShowBalance, setCurrentConfigShowBalance] = useState()
	const [currentServiceSelected, setCurrentServiceSelected] = useState<ServiceInterface[]>([])
	const [serviceSelectedCache, setServiceSelectedCache] = useState<any>({})
	const [services, setServices] = useState<ServiceInterface[]>([])
	const [serviceGroups, setServiceGroups] = useState<ServiceGroupedInterface[]>([])
	const [isAllow, setIsAllow] = useState(true)
	const [draftShipment, setDraftShipment] = useState<DraftShipmentInterface>()
	const [shimmentFees, setShimmentFees] = useState([])
	const [loadingCreateShipment, setLoadingCreateShipment] = useState(false)
	const [addressSupported, setAddressSupported] = useState(false)
	const [currency, setCurrency] = useState('')
	const [openAddressList, setOpenAddressList] = useState(false)
	const [currentServiceHasError, setCurrentServiceHasError] = useState<any>()

	const getAccountBalance = async () => {
		try {
			const res = await supplierRepository.getAccountBalance()
			const accountBalanceFiltered = res.filter((acc: AccountBalanceInterface) => {
				return acc.connection.provider?.shipmentServices[0].enable
			})
			setAccountBalance(accountBalanceFiltered)
		} catch (err) {}
	}

	const getAddressList = (isReset?: boolean) => {
		setIsLoading(true)
		cartRepository
			.getAddressList()
			.then((response) => {
				setDeliveryAddresses(response)
				if (!deliveryAddress || isReset) {
					let temp = response.find((x: any) => x.isDefault)
					setDeliveryAddress(temp)
				} else {
					let temp = response.find((x: any) => x.id === deliveryAddress.id)
					setDeliveryAddress(temp)
				}
			})
			.catch((err) => {})
			.finally(() => {
				setIsLoading(false)
			})
	}

	useEffect(() => {
		if (accountBalance && accountBalance.length > 0) {
			let accDefault = accountBalance.find((x) => x.connection.defaultConnection)
			if (accDefault) {
				setAccountDefault(accDefault)
			} else {
				setAccountDefault(accountBalance[0])
			}
		}
	}, [accountBalance])

	const makeDeliveryAddress = (address: any) => {
		setDeliveryAddress(address)
	}

	const updatePersonalNote = (value: string, callback: () => void) => {
		setPersonalNote(value)
		callback()
	}

	const updateEmployeeNote = (value: string, callback: () => void) => {
		setEmployeeNote(value)
		callback()
	}

	const selectChangeHandler = (code: string) => {
		let temp = accountBalance.find((x: any) => x.connection.id === code)
		if (temp) {
			setAccountDefault(temp)
		}
	}

	const onSubmitCreateModal = (body: any) => {
		setIsLoading(true)
		if (selectedAddress) {
			cartRepository
				.updateAddress(selectedAddress.id, body)
				.then((response) => {
					M24Notification.messageSuccess(t('message.success'))
					getAddressList()
					handleCloseAddressCreate()
					setSelectedAddress(null)
					setOpenAddressList(true)
				})
				.catch((error) => {
					M24ErrorUtils.showError(t, error)
				})
				.finally(() => {
					setIsLoading(false)
				})
		} else {
			cartRepository
				.createAddress(body)
				.then((response) => {
					M24Notification.messageSuccess(t('message.success'))
					if (deliveryAddresses.length > 0) {
						setOpenAddressList(true)
					}
					getAddressList()
					handleCloseAddressCreate()
				})
				.catch((error) => {
					M24ErrorUtils.showError(t, error)
				})
				.finally(() => {
					setIsLoading(false)
				})
		}
	}

	const submitCreateShipmentHandler = async (formValues: FormValues) => {
		const { refCustomerCode, refShipmentCode, expectedPackages } = formValues
		if (accountDefault && deliveryAddress) {
			const provider = accountDefault.connection.provider.code
			const providerUsername = accountDefault.connection.name
			const addressId = deliveryAddress.id
			const services = lodash.map(currentServiceSelected, 'code')
			setLoadingCreateShipment(true)
			try {
				await shipmentRepository.createShipments({
					provider,
					providerUsername,
					addressId,
					refCustomerCode,
					expectedPackages,
					personalNote,
					employeeNote,
					refShipmentCode,
					services,
				})
				M24Notification.messageSuccess(t('shipments.createSuccess'), '', 6)
				setLoadingCreateShipment(false)
				navigate('/shipments', { replace: true })
			} catch (err) {
				setLoadingCreateShipment(false)
				M24ErrorUtils.showError(t, err)
			}
		}
	}

	const changeService = (items: any) => {
		if (accountDefault && accountDefault.connection) {
			setCurrentServiceSelected(items)
			let temp = lodash.cloneDeep(serviceSelectedCache)
			temp[accountDefault.connection.id] = items
			setServiceSelectedCache(temp)
		}
	}

	const getServices = useCallback(() => {
		shipmentRepository
			.getShipmentServices(
				lodash.get(accountDefault, 'connection.name', ''),
				lodash.get(accountDefault, 'connection.provider.code', '')
			)
			.then((res) => {
				let otherServices = lodash.sortBy(
					res.filter((x: any) => !x.serviceGroup),
					'position'
				)
				let services = lodash.sortBy(
					res.filter((x: any) => x.serviceGroup),
					'position'
				)
				let items = lodash
					.chain(services)
					.groupBy('serviceGroup.code')
					.map((value, key) => {
						let group = value[0].serviceGroup
						return { ...group, services: value }
					})
					.value()
				let temp: any = []
				temp.push({
					code: 'other',
					name: t('shopping-cart.otherService'),
					services: otherServices,
				})
				temp = temp.concat(items)
				setServices(res)
				setServiceGroups(temp)
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err)
			})
	}, [accountDefault, t])

	const createDraftShipments = useCallback(async () => {
		setNoticeWarehouse('')
		const provider = lodash.get(accountDefault, 'connection.provider.code', '')
		const providerUsername = lodash.get(accountDefault, 'connection.name', '')
		const services = lodash.map(currentServiceSelected, 'code')
		const addressId = lodash.get(deliveryAddress, 'id', '')
		setIsLoading(true)
		if (provider && providerUsername && services.length > 0 && addressId) {
			try {
				const res = await shipmentRepository.createDraftShipment({
					provider,
					providerUsername,
					services,
					addressId,
				})

				const notPreferredWareHouse = res?.notices.find((notice: any) => notice.title === 'not_preferred_warehouse')
				setNoticeWarehouse(notPreferredWareHouse ? notPreferredWareHouse.reference.name : '')
				setDraftShipment(res)
				setIsLoading(false)
			} catch (err) {
				setIsLoading(false)
				if (lodash.get(err, 'response.data.title') === 'shipment_config_disabled') {
					notification.error({ message: t(`message.${lodash.get(err, 'response.data.title')}`), duration: 6 })
				}
			}
		}
	}, [accountDefault, currentServiceSelected, deliveryAddress, t])

	useEffect(() => {
		if (accountDefault) {
			getServices()
		}
	}, [getServices, accountDefault])

	const validAddress = useCallback(() => {
		setIsLoading(true)
		setAddressSupported(false)
		if (!accountDefault) return
		cartRepository
			.checkAddressSupport({
				location: deliveryAddress?.location,
				provider: lodash.get(accountDefault, 'connection.provider.code', ''),
			})
			.then((response) => {
				setAddressSupported(true)
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err, {
					value: lodash.get(accountDefault, 'connection.provider.name', ''),
				})
			})
			.finally(() => {
				setIsLoading(false)
			})
	}, [accountDefault, deliveryAddress?.location, t])

	useEffect(() => {
		validAddress()
	}, [validAddress])

	useEffect(() => {
		setCurrentServiceSelected(services.filter((service: ServiceInterface) => service.defaultApplied))
	}, [services])

	useEffect(() => {
		createDraftShipments()
	}, [createDraftShipments])

	useEffect(() => {
		getAddressList()
		getAccountBalance()
	}, [])

	useEffect(() => {
		setCurrentConfigShowBalance(lodash.get(localStorageRead(TENANT_KEY), 'config.showBalance', ''))
	}, [])

	useEffect(() => {
		let isAllow
		if (
			!addressSupported ||
			(currentServiceSelected && currentServiceSelected.length === 0) ||
			deliveryAddresses.length === 0 ||
			!deliveryAddress
		)
			isAllow = false
		else {
			let groups = serviceGroups.filter((x: ServiceGroupedInterface) => x.required)
			if (groups && groups.length > 0) {
				groups.forEach((group: ServiceGroupedInterface) => {
					let temp = currentServiceSelected.find(
						(x: ServiceInterface) => x.serviceGroup && x.serviceGroup.code === group.code
					)
					if (!temp) {
						isAllow = false
					} else {
						const requireGroups = temp.requireGroups
						if (requireGroups && requireGroups.length > 0) {
							requireGroups.forEach((requireGroup: string[]) => {
								const requireServiceSelect = currentServiceSelected.find(
									(curServiceSelected: ServiceInterface) =>
										curServiceSelected.serviceGroup?.code === requireGroup
								)
								isAllow = !!requireServiceSelect
							})
						} else {
							isAllow = true
						}
					}
				})
			} else {
				isAllow = true
			}
		}

		setIsAllow(!!isAllow)
	}, [deliveryAddresses.length, addressSupported, currentServiceSelected, deliveryAddress, serviceGroups])

	// Modal actions
	const handleCloseAddressList = () => {
		setOpenAddressList(false)
	}

	const openAddressCreate = () => {
		setOpenAddressList(false)
		setOpenModalAdd(true)
	}

	const showModalEditAddress = (item: any) => {
		setOpenAddressList(false)
		setSelectedAddress(item)
		setOpenModalAdd(true)
	}

	const handleCloseAddressCreate = () => {
		setOpenModalAdd(false)
		setSelectedAddress(null)
		if (deliveryAddresses && deliveryAddresses.length > 0) setOpenAddressList(true)
		else setOpenAddressList(false)
	}

	const handleCurServiceHasError = (obj: any) => {
		setCurrentServiceHasError(obj)
	}

	return (
		<>
			<MainLayout title={t('shipments.pageTitle')}>
				<div className='shipment'>
					<div className='box box-services'>
						<div className='connections-selection'>
							<ConnectionSelection
								accountBalance={accountBalance}
								onChangeHandler={selectChangeHandler}
								accountDefault={accountDefault}
								currentConfigShowBalance={currentConfigShowBalance}
							/>
						</div>
						<div className='flex align-items-center mg-bt-6'>
							<span className='box-services__label mg-r-12'>{t('shipments.servicesAttach')}</span>
							<span className='box-divider'></span>
						</div>
						<div className='box-services__attach'>
							<Services
								setCheckedList={changeService}
								checkedList={currentServiceSelected}
								services={services}
								serviceGroups={serviceGroups}
								setIsAllow={(val: boolean) => setIsAllow(val)}
								handleCurServiceHasError={handleCurServiceHasError}
							/>
						</div>

						<div className='flex align-items-center mg-bt-6'>
							<span className='box-services__label mg-r-12'>{t('shipment.address')}</span>
							<span className='box-divider mg-r-12'></span>
							{deliveryAddresses && deliveryAddresses.length > 0 && (
								<Button
									type={'text'}
									className={
										'flr txt-size-h7 robotoregular px-0 txt-color-primary h-24 flex align-items-center'
									}
									onClick={() => setOpenAddressList(true)}
									icon={<i className='fad fa-cog mg-r-5' />}>
									{t('shopping-cart.editAddress')}
								</Button>
							)}
						</div>
						{deliveryAddresses?.length === 0 && (
							<Empty
								className='empty-address'
								description={
									<>
										<span className='mg-l-2 whitespace mg-bt-8'>{t('shipments.addressEmpty')}</span>
										<Button
											className='btn-df btn-df-secondary-bg-white fsz-14 line-h-22 py-5'
											icon={<i className='fa-solid fa-plus mg-r-10'></i>}
											onClick={() => setOpenModalAdd(true)}>
											{t('shipments.createAddress')}
										</Button>
									</>
								}
								image={<Image preview={false} className={'icon-empty'} src={EmptyImage} />}
							/>
						)}
						{deliveryAddresses?.length > 0 && (
							<div className='box-services__addresses'>
								<AddressInfo
									icon={<i className='fa-solid fa-user'></i>}
									value={deliveryAddress?.receiver}
									valueClasses='receiver'
								/>
								<AddressInfo
									icon={<i className='fa-solid fa-house-chimney'></i>}
									value={`${deliveryAddress?.address1 ? deliveryAddress?.address1 : ''} ${
										deliveryAddress?.display ? deliveryAddress?.display : ''
									}`}
								/>
								<AddressInfo
									icon={<i className='fa-solid fa-phone-flip'></i>}
									value={deliveryAddress?.phoneNumber}
								/>
								{deliveryAddress && deliveryAddress.isDefault && (
									<AddressInfo
										icon={<i className='fa-solid fa-gear'></i>}
										value={t('address.status-isDefault')}
									/>
								)}

								{noticeWarehouse && noticeOneTime && (
									<div className={'box-services__addresses-notice'}>
										<i className='fa-solid fa-circle-exclamation mg-r-12'></i>
										<span
											className='content whitespace-pre-wrap break-word mg-r-12'
											dangerouslySetInnerHTML={{
												__html: t('cart.not_preferred_warehouse', {
													code: noticeWarehouse,
												}),
											}}></span>
										<i
											className='cursor-pointer fas fa-times mg-r-12'
											onClick={() => {
												setNoticeWarehouse('')
												setNoticeOneTime(false)
											}}></i>
									</div>
								)}

								<div className='flex align-items-center mg-t-12 mg-bt-6'>
									<span className='box-services__label mg-r-12'>{t('shipment.orderShipmentNote')}</span>
									<div className='box-divider'></div>
								</div>
							</div>
						)}

						<div className='box-services__note'>
							<Note
								note={personalNote}
								submitHandler={updatePersonalNote}
								label={t('shipments.personalNote')}
								title={t('shopping-cart.personalNote')}
							/>
							<Note
								note={employeeNote}
								submitHandler={updateEmployeeNote}
								label={t('shipments.employeeNote')}
								title={t('shipment.employeeNoteTItleModal')}
							/>
						</div>
					</div>
					<ShipmentFeesAndForm
						calculating={isLoading}
						draftShipment={draftShipment}
						submitHandler={submitCreateShipmentHandler}
						loading={loadingCreateShipment}
						allowCreate={isAllow}
						currency={currency}
						currentServiceHasError={currentServiceHasError}
						checkedList={currentServiceSelected}
						services={services}
					/>
				</div>
			</MainLayout>
			{openAddressList && (
				<Address
					isVisible={openAddressList}
					onCancel={handleCloseAddressList}
					openAddressCreate={openAddressCreate}
					addressList={deliveryAddresses}
					updateAddress={showModalEditAddress}
					getAddressList={getAddressList}
					makeDeliveryAddress={makeDeliveryAddress}
					deliveryAddress={deliveryAddress}
				/>
			)}
			{openModalAdd && (
				<CreateAddressModal
					isVisible={openModalAdd}
					onCancel={handleCloseAddressCreate}
					onSubmit={onSubmitCreateModal}
					datasource={selectedAddress}
					isDefault={deliveryAddresses.length === 0}
				/>
			)}
		</>
	)
}

export default CreateShipment
