import { Repository } from '../core/repositories/Repository'
import { AxiosResponse } from 'axios'
import { url } from '../core/helpers/string'
import { httpConfig } from '../core/config/http'
import { API_BASE_URL } from '../core/config'
import lodash from 'lodash'
import { ClaimReasonCriteria } from '../Interface/ClaimReasonCriteria'
import { ClaimReasonInterface } from '../Interface/ClaimReasonInterface'
import { ClaimSolutionInterface } from '../Interface/ClaimSolutionInterface'
export class CategoryRepository extends Repository {
	constructor() {
		super(httpConfig, false)
		this.setBaseURL(url(API_BASE_URL, 'categories'))
	}

	public getConnectionsStatus = (): Promise<any> => {
		return this.http.get(`connection-statuses`, {}).then((response: AxiosResponse<any>) => {
			return response.data
		})
	}
	public getStatuses = (): Promise<any> => {
		return this.http.get(`order-statuses`, { params: { size: 1000 } }).then((response: AxiosResponse<any>) => {
			return response.data
		})
	}
	public getMarketPlaces = (): Promise<any> => {
		return this.http.get(`/marketplaces`, { params: { size: 1000 } }).then((response: AxiosResponse<any>) => {
			return response.data
		})
	}
	public getProvider = (): Promise<any> => {
		return this.http.get('providers', { params: { size: 1000 } }).then((response: AxiosResponse<any>) => {
			return response.data
		})
	}

	public getPackageStatuses = (): Promise<any> => {
		return this.http.get(`package-statuses`).then((response: AxiosResponse<any>) => {
			return lodash.sortBy(response.data, 'position')
		})
	}

	public getProviderByCode = (providerCode: string): Promise<any> => {
		return this.http.get(`providers/${providerCode}`)
	}

	public getRequestDeliveryStatuses = (): Promise<any> => {
		return this.http.get(`delivery-request-statuses`).then((response: AxiosResponse<any>) => {
			return lodash.sortBy(response.data, 'position')
		})
	}
	public getFaqs = (provider: string): Promise<any> => {
		return this.http.get(`/faqs/${provider}/recharge_instructions`).then((response: AxiosResponse<any>) => {
			return response.data
		})
	}

	public getPaymentStatuses = (): Promise<any> => {
		return this.http.get(`/payment-statuses`).then((response: AxiosResponse<any>) => {
			return response.data
		})
	}

	public getPaymentMethods = (): Promise<any> => {
		return this.http.get(`/payment-methods`).then((response: AxiosResponse<any>) => {
			return response.data
		})
	}
	public getTransactionStatuses = (): Promise<any> => {
		return this.http.get(`/transaction-statuses`).then((response: AxiosResponse<any>) => {
			return response.data
		})
	}
	public getTransactionTypes = (): Promise<any> => {
		return this.http.get(`/transaction-types`).then((response: AxiosResponse<any>) => {
			return response.data
		})
	}

	public getPaymentRequestTypes = (): Promise<any> => {
		return this.http.get(`/payment-request-types`).then((response: AxiosResponse<any>) => {
			return response.data
		})
	}

	public getShipmentStatuses = (): Promise<any> => {
		return this.http.get(`/shipment-statuses`).then((response: AxiosResponse<any>) => {
			return response.data
		})
	}

	getReasonsOfClaim = (provider: string, type: string): Promise<ClaimReasonInterface[]> => {
		return this.http
			.get(`/claim-reasons`, {
				params: { offset: 0, limit: 100, type, provider },
			})
			.then((response: AxiosResponse) => {
				return response.data
			})
	}
	getSolutionsOfClaim = (provider: string): Promise<ClaimSolutionInterface[]> => {
        return this.http.get(`/claim-solutions`,
            {params:{offset:0,limit:100, provider}}).then((response:AxiosResponse) => {
            return response.data;
			})
	}
    public getShipmentPackageStatuses = () : Promise<any> => {
        return this.http.get(`/parcel-statuses`, {params: {size: 1000}}).then((response:AxiosResponse) => {
            return lodash.sortBy(response.data, 'position');
        })
    }
}

export const categoryRepository: CategoryRepository = new CategoryRepository()

