import { Button, Form, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { UploadFile } from 'antd/lib/upload/interface';
import { SellInfoSection } from './SellInfoSection';
import { FormInstance } from 'antd/lib/form/Form';
import { TransportSection } from './TransportSection';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { CommonInfoData, CommonInfoSection } from './CommonInfoSection';

import { AxiosResponse } from 'axios';
import { OtherSection } from './OtherSection';
import { DetailSection } from './DetailSection';
import { connectMarketRepository } from '../../repositories/ConnectMarketRepository';
import {
  BodyEditProductForSellInterface,
  DetailFieldInterface,
  EditProductForSellFormNames,
  ProductForSellInterface,
} from '../../Interface/ProductInterface';
import { warehouseRepository } from '../../repositories/ProductWarehouseRepository';
import { NavLeftBar } from './NavLeftBar';
import './styles.scss';
import M24Notification from '../../utils/M24Notification';
import {
  detectErrorFromShopee,
  getBodyEditProduct,
  initialForm,
  scrollToFieldOptions,
} from './helpers';
import { VariantPropertyInterface } from '../../Interface/StoredProductInterface';

export enum SectionIds {
  COMMON_INFO = '#common-info-section',
  SELL_INFO = '#sell-info',
  DETAILS_INFO = '#details-info',
  TRANSPORT_INFO = '#transport-section',
  OTHER = '#other-section',
}

interface EditProductForSellProps {
  productId: string;
  productStatus?: 'SELLING' | 'PENDING';
}

enum SellStatusEnum {
  BANNED = 'BANNED',
  DELETED = 'DELETED',
  NORMAL = 'NORMAL',
  UNLIST = 'UNLIST',
}

const EditProductForSell: FC<EditProductForSellProps> = ({
  productId,
  productStatus = 'PENDING',
}: EditProductForSellProps) => {
  const { t } = useTranslation();
  const commonInfoRef: React.MutableRefObject<any> = useRef();

  const [form]: [FormInstance<Record<EditProductForSellFormNames, any>>] =
    Form.useForm();

  const [loading, setLoading] = useState<boolean>(true);
  const [productImages, setProductImages] = useState<UploadFile<unknown>[]>([]);
  const [isDisableSubmitButton, setIsDisableSubmitButton] =
    useState<boolean>(true);
  const [detailFields, setDetailFields] = useState<DetailFieldInterface[]>([]);
  const [categoryId, setCategoryId] = useState<number | undefined>();
  const [intergration, setIntergration] = useState<
    { integrationId: string; chanel: string } | undefined
  >();
  const [productVideo, setProductVideo] = useState<string>('');
  const [isGetAttribute, setIsGetAttribute] = useState<boolean>(false);
  const [variantProperties, setVariantProperties] = useState<string[]>();
  const [sellStatus, setSellStatus] = useState<SellStatusEnum>();

  const navigate: NavigateFunction = useNavigate();

  const isSellingProductPage: boolean = useMemo(
    (): boolean => productStatus === 'SELLING',
    [productStatus]
  );

  useEffect(() => {
    const getProduct = (productId: string) =>
      isSellingProductPage
        ? warehouseRepository.getSellingProductDetails(productId)
        : warehouseRepository.getPendingProductDetails(productId);

    getProduct(productId)
      .then(({ data }: AxiosResponse<ProductForSellInterface>): void => {
        initialForm(form, data);

        setProductVideo(data.productVideo);

        setCategoryId(data.categoryId);

        setIntergration({
          chanel: data.channel,
          integrationId: data.integrationId,
        });

        setVariantProperties(
          data.skus &&
            data.skus[0].variantProperties?.map(
              (p: VariantPropertyInterface) => p.name
            )
        );

        const imgMapping: UploadFile<unknown>[] = data.productImages.map(
          (url: string, index: number) => ({
            uid: String(index),
            name: 'image.png',
            status: 'done',
            url,
          })
        );
        setProductImages(imgMapping);
        setIsDisableSubmitButton(true);
      })
      .catch(() => {
        M24Notification.notifyError(
          t('message.titleFailed'),
          t(`productForSell.get-detail-error`),
          '',
          4
        );
        setIsDisableSubmitButton(true);
      })
      .finally(() => {
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   warehouseRepository.getSellingProductStatus()
  // }, [])

  useEffect(() => {
    window.onbeforeunload = function (e: any) {
      e = e || window.event;
      if (e) {
        e.returnValue = 'Sure?';
      }
      return 'Sure?';
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    if (!categoryId || !intergration) {
      setDetailFields([]);
      return;
    }

    setIsGetAttribute(true);
    connectMarketRepository
      .getAllAttributesOfCategory(
        intergration?.chanel,
        intergration?.integrationId,
        categoryId
      )
      .then((res: any): void => {
        const sorted: any = res.data.sort(
          (a: any, b: any) => a.attributeId - b.attributeId
        );
        setDetailFields(sorted);
      })
      .catch(() => {
        setDetailFields([]);
        M24Notification.notifyError(
          t('message.titleFailed'),
          t(`productForSell.get-attribute-error`),
          '',
          4
        );
        setIsDisableSubmitButton(true);
      })
      .finally(() => {
        setIsGetAttribute(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, intergration]);

  const onFinish = (
    values: Record<EditProductForSellFormNames, any>,
    callback?: () => void
  ) => {
    const richTextFields: CommonInfoData =
      commonInfoRef.current?.getCommonInfoValues();

    if (productImages.length < 1) {
      form.scrollToField('productImageMock', scrollToFieldOptions);
      return;
    }

    if (values.skus?.length >= 50) {
      M24Notification.notifyError(
        t('message.titleFailed'),
        t('productForSell.validate-max-length-skus'),
        '',
        4
      );
      return;
    }

    let hasLogistic: boolean = false;
    Object.keys(values.logistics).forEach((key: string) => {
      if (values.logistics[key]) {
        hasLogistic = true;
      }
    });

    if (!hasLogistic) {
      M24Notification.notifyError(
        t('message.titleFailed'),
        t('productForSell.validate-logistic'),
        '',
        4
      );
      return;
    }

    if (!intergration) {
      return;
    }

    setIsDisableSubmitButton(true);
    setLoading(true);
    const params: BodyEditProductForSellInterface = getBodyEditProduct(
      productImages,
      values
    );

    const body: BodyEditProductForSellInterface = {
      ...params,
      productVideo,
      detail: richTextFields.detail,
    };

    const editProduct: any = (
      productId: string,
      body: BodyEditProductForSellInterface
    ) =>
      isSellingProductPage
        ? warehouseRepository.editSellingProductDetails(productId, body)
        : warehouseRepository.editPendingProductDetails(productId, body);

    editProduct(productId, body)
      .then(() => {
        if (callback) {
          callback();
        } else {
          navigate('/pending-products/shopee');
        }
      })
      .catch((error: any) => {
        if (error.response?.status !== 400) {
          M24Notification.notifyError(
            t('message.titleFailed'),
            t(`message.try_again`),
            '',
            2
          );
        }
        setLoading(false);
        setIsDisableSubmitButton(false);
      });
  };

  const saveAndPostForSell = () => {
    form
      .validateFields()
      .then((values) => {
        onFinish(values, () => {
          setIsDisableSubmitButton(true);
          warehouseRepository
            .postForSellProduct([productId])
            .then((error: any) => {
              const errorMsg: string = error.data[0]?.msg;
              if (errorMsg) {
                M24Notification.notifyError(
                  t('message.titleFailed'),
                  detectErrorFromShopee(errorMsg),
                  '',
                  4
                );
              } else {
                M24Notification.notifySuccess(
                  t('productForSell.post-to-sell-success'),
                  '',
                  '',
                  4
                );
                navigate('/pending-products/shopee');
              }
            })
            .catch(() => {
              M24Notification.notifyError(
                t('message.titleFailed'),
                t(`message.try_again`),
                '',
                4
              );
            })
            .finally(() => {
              setLoading(false);
              setIsDisableSubmitButton(false);
            });
        });
      })
      .catch(({ errorFields, ...a }) => {
        if (errorFields) {
          form.scrollToField(errorFields[0]?.name[0], scrollToFieldOptions);
        }
      });
    return;
  };

  return (
    <div className="product-for-sell-editing">
      <NavLeftBar
        items={[
          {
            id: SectionIds.COMMON_INFO,
            title: t('productForSell.general-info-box'),
          },
          {
            id: SectionIds.SELL_INFO,
            title: t('productForSell.selling-detail-box'),
          },
          {
            id: SectionIds.DETAILS_INFO,
            title: t('productForSell.detail-info'),
          },
          {
            id: SectionIds.TRANSPORT_INFO,
            title: t('productForSell.delivering-detail-box'),
          },
          {
            id: SectionIds.OTHER,
            title: t('productForSell.other'),
          },
        ]}
      />

      <div className="form-container">
        <Spin spinning={loading}>
          <Form
            form={form}
            onFinish={onFinish}
            onValuesChange={() => {
              setIsDisableSubmitButton(false);
            }}
            scrollToFirstError={scrollToFieldOptions}
          >
            <CommonInfoSection
              ref={commonInfoRef}
              form={form}
              productImages={productImages}
              intergration={intergration}
              categoryId={categoryId}
              productVideo={productVideo}
              setProductVideo={setProductVideo}
              setProductImages={setProductImages}
              setIsDisableSubmitButton={setIsDisableSubmitButton}
              setCategoryId={setCategoryId}
            />

            <SellInfoSection
              form={form}
              setIsDisableSubmitButton={setIsDisableSubmitButton}
              variantProperties={variantProperties}
              setVariantProperties={setVariantProperties}
              type="PRODUCT_FOR_SELL"
            />

            <DetailSection
              form={form}
              detailFields={detailFields}
              isGetAttribute={isGetAttribute}
            />

            <TransportSection
              form={form}
              intergration={intergration}
              setIsDisableSubmitButton={setIsDisableSubmitButton}
            />

            <OtherSection form={form} />

            <div className="save-button">
              <div className="left"></div>
              <div className="button-container">
                <Button
                  className="m24-button mgr8"
                  type="default"
                  htmlType="button"
                  onClick={() => navigate('/pending-products/shopee')}
                >
                  {t('productForSell.cancel')}
                </Button>

                <Button
                  className="m24-button mgr8 bordered-btn"
                  htmlType="submit"
                  type="primary"
                  disabled={isGetAttribute || isDisableSubmitButton}
                >
                  {t('productForSell.save-info')}
                </Button>

                {!isSellingProductPage && (
                  <Button
                    className="m24-button bd0"
                    type="primary"
                    htmlType="button"
                    disabled={isGetAttribute || isDisableSubmitButton}
                    onClick={saveAndPostForSell}
                  >
                    {t('productForSell.save-and-post')}
                  </Button>
                )}
              </div>
            </div>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export { EditProductForSell };
