import React, { useEffect, useState } from "react";

import {useTranslation} from "react-i18next";
import {Avatar, Button, Checkbox, ConfigProvider, Empty, List, Modal, Spin} from "antd";
import {VendorServiceInterface} from "../../../Interface/VendorServiceInterface";
import {ServicePackageInterface} from "../../../Interface/UserServiceInterface";
import GobizLogo from "../../../resources/images/gobiz-128.png";
import PackageItem from "./PackageItem";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Link } from "react-router-dom";


type PurchaseModalProps = {
    vendorPackages: ServicePackageInterface[],
    selectedVendor: VendorServiceInterface;
    selectedPackage: ServicePackageInterface;
    handleSelectPackage: (pk: ServicePackageInterface) => void;
    isLoading: boolean;
    handleNextStep:()=>void;
    onCancel:()=>void;
    checked: boolean
    handleChecked: (val: boolean) => void
}


function VendorPackages(props: PurchaseModalProps) {
    const {t} = useTranslation();
    const {
        vendorPackages, selectedVendor, selectedPackage, isLoading,
        handleSelectPackage,handleNextStep,onCancel,checked, handleChecked
    } = props;

    
    const [canOrderPackage, setCanOrderPackage] = useState(false);
    
    useEffect(() => {
        let canOrder = false
        if (selectedPackage.id.length > 1 ) {
            canOrder = true && checked
        }else {
            canOrder = checked && false
        }
        setCanOrderPackage(canOrder)
    }, [checked, selectedPackage.id.length])
 
     const checkboxHandler = (e: CheckboxChangeEvent) => {
        handleChecked(e.target.checked)
    };

    return (
        <div className={'package-list-container'}>
            <div className={'package-list'}>
                <ConfigProvider renderEmpty={() => <Empty
                    image={<Avatar src={GobizLogo} size={80} shape='square' className="gobiz-gray"/>}
                    description={<span
                        className={'txt-size-h7 robotoregular txt-color-black'}>{t('profile.empty-package-list')}</span>}/>}>
                    <div className={'vendor-package-list'}>
                        <Spin spinning={isLoading}>
                            <List
                                dataSource={vendorPackages}
                                renderItem={(ps: ServicePackageInterface) => {
                                    if (ps.status !== 'INACTIVE')
                                        return (
                                            <div id={'package-item'}>
                                                <PackageItem pk={ps}
                                                             handleSelectPackage={handleSelectPackage!}
                                                             selectedPk={selectedPackage!}
                                                             selectedVendor={selectedVendor!}
                                                />
                                            </div>
                                        )
                                }}
                            />
                        </Spin>
                    </div>
                </ConfigProvider>
            </div>
            <Checkbox onChange={checkboxHandler} checked={checked} className='mg-t-24 mg-bt-16 mg-r-9 txt-color-black mg-l-44'></Checkbox><span dangerouslySetInnerHTML={{__html: t('profile.confirmRule')}}></span>
            <div className={'package-list-footer '}>
                <Button className={'cancel mgr10'}
                        onClick={onCancel}
                >
                    <i className="fa-solid fa-xmark mgl5 mgr10"/>
                    {t('profile.package-order-cancel')}
                </Button>
                <Button
                    className={'purchase txt-capitalize'}
                    disabled={!canOrderPackage}
                    onClick={handleNextStep}
                >
                    <i className="fa-solid fa-arrow-right-long mgl5 mgr10 "/>
                    {t('profile.package-order-submit')}
                </Button>
            </div>
        </div>

    );
}

export default VendorPackages;
