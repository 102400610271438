import { Button, Form, Input, Modal, Radio, Spin } from 'antd';
import { FormInstance, useForm, useWatch } from 'antd/lib/form/Form';
import { AxiosResponse } from 'axios';
import { isEqual } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BillTypeEnum,
  CreatePaymentInvoiceBody,
  InvoiceData,
  PaymentInvoiceInterface,
  RequestBillFormNames,
} from '../../Interface/PaymentInterface';
import { userServiceRepository } from '../../repositories/UserServiceRepository';
import M24Notification from '../../utils/M24Notification';

interface IProps {
  isShowRequestBillModal: boolean;
  invoiceData?: InvoiceData;
  closeModal: () => void;
  finishedCallback: () => void;
}

export const ModalRequestBill: FC<IProps> = ({
  isShowRequestBillModal,
  invoiceData,
  finishedCallback,
  closeModal,
}) => {
  const [form]: [FormInstance<Record<RequestBillFormNames, any>>] = useForm();
  const billType: BillTypeEnum = useWatch(RequestBillFormNames.billType, form);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState(false);
  const [curData, setCurData] = useState<any>();
  
  useEffect(() => {
    if (!isShowRequestBillModal) {
      return;
    }

    if (!invoiceData?.invoiceId) {
      form.setFieldsValue({
        [RequestBillFormNames.billType]: BillTypeEnum.COMPANY
      })
      return
    }

    setIsLoading(true);
    userServiceRepository
      .getPaymentInvoice(invoiceData.invoiceId)
      .then(({ data }: AxiosResponse<PaymentInvoiceInterface>): void => {
        setCurData(data)
        form.setFieldsValue({
          [RequestBillFormNames.billType]: data.type,
          [RequestBillFormNames.taxCode]:
            data.type === BillTypeEnum.COMPANY ? data.tax : '',
          [RequestBillFormNames.companyName]:
            data.type === BillTypeEnum.COMPANY ? data.name : '',
          [RequestBillFormNames.companyAddress]:
            data.type === BillTypeEnum.COMPANY ? data.address : '',
          [RequestBillFormNames.companyEmail]:
            data.type === BillTypeEnum.COMPANY ? data.email : '',
          [RequestBillFormNames.fullName]:
            data.type === BillTypeEnum.PERSONAL ? data.name : '',
          [RequestBillFormNames.address]:
            data.type === BillTypeEnum.PERSONAL ? data.address : '',
          [RequestBillFormNames.email]:
            data.type === BillTypeEnum.PERSONAL ? data.email : '',
        });
        setIsLoading(false);
      })
      .catch(() => {
        M24Notification.notifyError(
          t('message.titleFailed'),
          t(`message.try_again`),
          '',
          5
        );
        setIsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowRequestBillModal, invoiceData]);

  const handleSuccess: any = () => {
    M24Notification.notifySuccess(
      t('message.success'),
      t(
        invoiceData?.invoiceId
          ? 'profile.payment-edit-success'
          : 'profile.payment-create-success'
      ),
      '',
      5
    );
    onClose();
    finishedCallback();
  };

  const handleError: any = () => {
    M24Notification.notifyError(
      t('message.titleFailed'),
      t(`message.try_again`),
      '',
      5
    );
  };

  const onFinish = (values: Record<RequestBillFormNames, string>) => {
    if (!invoiceData?.code) {
      onClose();
      return;
    }
    setIsLoading(true);
    const body: CreatePaymentInvoiceBody =
      billType === BillTypeEnum.COMPANY
        ? {
            type: billType,
            tax: values[RequestBillFormNames.taxCode],
            name: values[RequestBillFormNames.companyName],
            address: values[RequestBillFormNames.companyAddress],
            email: values[RequestBillFormNames.companyEmail],
            order: invoiceData.code,
            owner: invoiceData.owner,
          }
        : {
            type: billType,
            name: values[RequestBillFormNames.fullName],
            address: values[RequestBillFormNames.address],
            email: values[RequestBillFormNames.email],
            order: invoiceData.code,
            owner: invoiceData.owner,
            tax: '',
          };

    if (invoiceData.invoiceId) {
      delete body.order;
      delete body.owner;
      userServiceRepository
        .updatePaymentInvoice(invoiceData.invoiceId, body)
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => {
          setIsLoading(false);
        });
      return;
    }

    userServiceRepository
      .createPaymentInvoice(body)
      .then(handleSuccess)
      .catch(handleError)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onChangeBillType = (e: any) => {
    form.setFieldsValue({
      [RequestBillFormNames.billType]: e.target.value,
    });

    if (e.target.value === BillTypeEnum.COMPANY) {
      form.validateFields([
        RequestBillFormNames.fullName,
        RequestBillFormNames.address,
        RequestBillFormNames.email,
      ]);
    } else {
      form.validateFields([
        RequestBillFormNames.taxCode,
        RequestBillFormNames.companyName,
        RequestBillFormNames.companyAddress,
        RequestBillFormNames.companyEmail,
      ]);
    }
  };

  const onClose = () => {
    closeModal();
    form.resetFields();
  };

  return (
    <Modal
      visible={isShowRequestBillModal}
      onCancel={onClose}
      footer={null}
      centered
      className="modal-common request-bill-modal"
      title={t(
        invoiceData?.invoiceId
          ? 'profile.payment-waiting-bill'
          : 'profile.payment-request-bill'
      )}
      width={749}
      closeIcon={<i className="fa-solid fa-xmark" />}
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          onFinish={onFinish}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
          autoComplete="off"
          labelAlign="left"
        >
          <div style={{minHeight: 176}}>
            <Form.Item
              name={RequestBillFormNames.billType}
              // initialValue={BillTypeEnum.COMPANY}
              labelCol={{ span: 0 }}
              wrapperCol={{ span: 24 }}
            >
              <Radio.Group onChange={onChangeBillType}>
                <Radio value={BillTypeEnum.COMPANY}>
                  {t('profile.payment-company-bill')}
                </Radio>

                <Radio value={BillTypeEnum.PERSONAL}>
                  {t('profile.payment-personal-bill')}
                </Radio>
              </Radio.Group>
            </Form.Item>

            {billType === BillTypeEnum.COMPANY && (
              <>
                <Form.Item
                  name={RequestBillFormNames.taxCode}
                  label={t('profile.payment-tax-code')}
                  rules={[
                    {
                      required: true,
                      message: t('profile.payment-tax-code-validate'),
                    }
                  ]}
                >
                  <Input
                    placeholder={t('profile.payment-tax-code-placeholder')}
                    className="m24-input"
                    autoFocus={true}
                    maxLength={20}
                  />
                </Form.Item>

                <Form.Item
                  name={RequestBillFormNames.companyName}
                  label={t('profile.payment-company-name')}
                  rules={[
                    {
                      required: true,
                      message: t('profile.payment-company-name-validate'),
                    },
                  ]}
                >
                  <Input
                    className="m24-input"
                    placeholder={t('profile.payment-company-name-placeholder')}
                  />
                </Form.Item>

                <Form.Item
                  name={RequestBillFormNames.companyAddress}
                  label={t('profile.payment-company-address')}
                  rules={[
                    {
                      required: true,
                      message: t('profile.payment-company-address-validate'),
                    },
                  ]}
                >
                  <Input
                    className="m24-input"
                    placeholder={t(
                      'profile.payment-company-address-placeholder'
                    )}
                  />
                </Form.Item>

                <Form.Item
                  name={RequestBillFormNames.companyEmail}
                  label={t('profile.payment-email')}
                  rules={[
                    {
                      required: true,
                      message: t('profile.payment-email-validate'),
                    },
                    {
                      type: 'email',
                      message: t('profile.payment-email-invalid'),
                    },
                  ]}
                >
                  <Input
                    className="m24-input"
                    placeholder={t('profile.payment-email-placeholder')}
                  />
                </Form.Item>
              </>
            )}
            {billType === BillTypeEnum.PERSONAL && (
              <>
                <Form.Item
                  name={RequestBillFormNames.fullName}
                  label={t('profile.payment-full-name')}
                  rules={[
                    {
                      required: true,
                      message: t('profile.payment-full-name-validate'),
                    },
                  ]}
                >
                  <Input
                    className="m24-input"
                    placeholder={t('profile.payment-full-name-placeholder')}
                    autoFocus={true}
                  />
                </Form.Item>

                <Form.Item
                  name={RequestBillFormNames.address}
                  label={t('profile.payment-address')}
                  rules={[
                    {
                      required: true,
                      message: t('profile.payment-address-validate'),
                    },
                  ]}
                >
                  <Input
                    className="m24-input"
                    placeholder={t('profile.payment-address-placeholder')}
                  />
                </Form.Item>

                <Form.Item
                  name={RequestBillFormNames.email}
                  label={t('profile.payment-email')}
                  rules={[
                    {
                      required: true,
                      message: t('profile.payment-email-validate'),
                    },
                    {
                      type: 'email',
                      message: t('profile.payment-email-invalid'),
                    },
                  ]}
                >
                  <Input
                    className="m24-input"
                    placeholder={t('profile.payment-email-placeholder')}
                  />
                </Form.Item>
              </>
            )}
          </div>

          <div className="dpl-flex justify-content-end mg-t-10">
            <Button className="mg-r-8 m24-button txt-capitalize" onClick={onClose}>
              <i className="fa-solid fa-xmark pd-r-8" />
              {t('profile.payment-cancel')}
            </Button>

            <Form.Item shouldUpdate={(prevValues, curValues) => {
              let isDiff
              if(billType === 'COMPANY') {
                 isDiff = curData?.tax !== curValues.taxCode || curData?.name !== curValues.companyName || curData?.address !== curValues.companyAddress || curData?.email !== curValues.companyEmail
              }else {
                  isDiff = curData?.name !== curValues.fullName || curData?.address !== curValues.address || curData?.email !== curValues.email
              }
              setDisabled(!isDiff)
              return isDiff
            }}>
              {({getFieldsValue}) => {
                const values: Record<RequestBillFormNames, any> =
                  getFieldsValue();
                const isNotNull =
                  billType === 'COMPANY'
                    ? values[RequestBillFormNames.taxCode] &&
                      values[RequestBillFormNames.companyName] &&
                      values[RequestBillFormNames.companyAddress] &&
                      values[RequestBillFormNames.companyEmail]
                    : values[RequestBillFormNames.fullName] &&
                      values[RequestBillFormNames.address] &&
                      values[RequestBillFormNames.email];
                return (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={
                      !isNotNull ||
                      form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length > 0 || disabled
                    }
                    className="m24-button txt-capitalize"
                  >
                    <i className="fa-solid fa-check pd-r-8" />
                    {t('profile.payment-confirm')}
                  </Button>
                );
              }}
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};
