import { RedoOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Image, Row, Select } from 'antd'
import lodash from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { handleInputChange } from '../../../utils/StringUtils'
const { Option } = Select
interface FilterProps {
	accounts: any
	setFilter: (filter: any) => void
	providers: any
	filter: any
	onSearch: (filter: any) => void
}

const Filter: React.FC<FilterProps> = (props) => {
	const { t } = useTranslation()
	const { accounts, setFilter, providers, filter, onSearch } = props
	const [showFilter, setShowFilter] = useState(true)
	const [accountsFilter, setAccountsFilter] = useState(accounts)
	
	useEffect(() => {
		if (filter.provider) {
			setAccountsFilter(accounts.filter((acc: any) => acc.connection.provider.code === filter.provider))
		} else setAccountsFilter(accounts)
	}, [filter, accounts])

	const clearFilter = () => {
		setFilter({})
		onSearch({})
	}
	const onChange = (key: string, value: any) => {
		let temp = handleInputChange(key, value, filter)
		if (key === 'provider') delete temp.providerUsername
		setFilter(temp)
	}
	return (
		<Row
			className={`white-box mg-bt-12 ${!showFilter ? 'pd-bt-0 cursor-pointer' : 'pd-imp-12'}`}
			onClick={!showFilter ? () => setShowFilter(!showFilter) : undefined}
			align='middle'>
			<Row justify='space-between' className='width100pc cursor-pointer' onClick={() => setShowFilter(!showFilter)}>
				<Col span={12}>
					<h3 className={`robotomedium fsz-16 line-h-24 txt-color-black ${!showFilter ? 'mg-0' : ''}`}>
						{t('availableOrders.listInStock')}
					</h3>
				</Col>
				<Col span={12} className='dpl-flex justify-content-end'>
					<i
						className={`fas ${showFilter ? 'fa-angle-up' : 'fa-angle-down'} txt-color-black fsz-14 cursor-pointer`}
						onClick={() => setShowFilter(!showFilter)}></i>
				</Col>
			</Row>
			{showFilter && (
				<Col span={24} className='form-filter'>
					<Row align='middle' gutter={[0, 8]} className='mg-bt-12' justify='start'>
						<span className='robotoregular fsz-14 line-h-22 mgr5'>{t('deliveryCreate.domain')}:</span>
						<Col span={4} className='mg-r-16'>
							<Select
								showSearch={false}
								className={'width100'}
								placeholder={t('requestDelivery.chosenSupplierPlaceholder')}
								value={lodash.get(filter, 'provider', undefined)}
								onChange={(val: string) => onChange('provider', val)}>
								{providers &&
									providers.length > 0 &&
									providers.map((prov: any) => (
										<Option key={prov.code} value={prov.code}>
											<div className='dpl-flex align-items-center'>
												<Image
													preview={false}
													src={lodash.get(prov, 'logo', '')}
													className='bd-none w-12 h-12 dpl-flex align-items-center mg-r-4'
												/>
												<span className='robotoregular fsz-14 line-h-22'>
													{lodash.get(prov, 'name', '')}
												</span>
											</div>
										</Option>
									))}
							</Select>
						</Col>
						<span className='robotoregular fsz-14 line-h-22 mgr5'>{t('delivery-create.providerAccount')}:</span>
						<Col span={5}>
							<Select
								showSearch={false}
								className={'width100'}
								placeholder={t('deliveryCreate.placeHolderChooseAcc')}
								value={lodash.get(filter, 'providerUsername', undefined)}
								onChange={(val: string) => onChange('providerUsername', val)}>
								{accountsFilter &&
									accountsFilter.length > 0 &&
									accountsFilter.map((acc: any) => (
										<Option key={acc.connection.id} value={acc.connection.id}>
											<div className='dpl-flex align-items-center'>
												<Image
													preview={false}
													src={lodash.get(acc, 'connection.provider.logo', '')}
													className='bd-none w-12 h-12 dpl-flex align-items-center mg-r-4'
												/>
												<span className='robotoregular fsz-14 line-h-22 mg-r-4'>
													{lodash.get(acc, 'connection.provider.code', '')}
												</span>
												<Divider type={'vertical'} className={'bd-color-black line-h-22 mg-0 mg-r-4'} />
												<span className='robotoregular fsz-14 line-h-22'>
													{lodash.get(acc, 'connection.name', '')}
												</span>
											</div>
										</Option>
									))}
							</Select>
						</Col>
					</Row>
					<Row className='dpl-flex justify-content-end aling-items-center'>
						{!lodash.isEmpty(filter) && (
							<Button
								className='clean-filter robotoregular txt-color-gray4 fsz-12 line-h-20 dpl-flex align-items-center
													flex-basis-btn-filter cursor-pointer bd-none mg-r-12'
								onClick={clearFilter}
								icon={<RedoOutlined className='mg-r-4' />}>
								<span className='mg-0'>{t('packages.refreshFilter')}</span>
							</Button>
						)}
						<Button
							disabled={false}
							onClick={() => onSearch(filter)}
							className='robotoregular txt-color-white bg-color-blue7 fsz-14 line-h-22 bd-none border-radius4
													px-12 py-3 flex-basis-btn-filter search-packages txt-capitalize'>
							{t('packages.search')}
						</Button>
					</Row>
				</Col>
			)}
		</Row>
	)
}

export default Filter

