import {Button, Input, Select, Spin} from "antd";
import React, {useContext, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {ThreadItem} from "./ThreadItem";
import moment from "moment";
import {BoxChatContext} from "../../context/BoxChatContext";
import lodash from "lodash";

interface FilterInterface {
    isRead?: boolean,
    date?: string,
    timestampFrom?: string
    timestampTo?: string
    type?: string,
    referenceCode?: string,
}


export const Thread = () => {
    const [filter, setFilter] = useState<FilterInterface>({
        isRead: undefined,
        date: undefined,
        type: undefined,
        referenceCode: undefined,
    })
    const {threads, threadLoading, onFilterThreads, onThreadScroll} = useContext(BoxChatContext)
    const {t} = useTranslation();
    const [searchState, setSearchState] = useState<any>();
    const [selectedAllStatus, setSelectedAllStatus] = useState<any>(true);


    const dates = [
        {code: '1week', name: t('chatBox.filterTimestamp1week')},
        {code: '3weeks', name: t('chatBox.filterTimestamp3weeks')},
        {code: '1month', name: t('chatBox.filterTimestamp1month')},
        {code: '2months', name: t('chatBox.filterTimestamp2months')},
        {code: '3months', name: t('chatBox.filterTimestamp3months')},
    ]

    const readStatuses = [
        {code: 'all', name: t('chatBox.filterStatusAll')},
        // {code: true, name: t('chatBox.filterStatusRead')},
        {code: 'false', name: t('chatBox.filterStatusUnRead')}
    ]

    const threadType = [
        {code: 'ORDER_COMMENT', name: t('chatBox.thread-type-order')},
        {code: 'CLAIM_COMMENT', name: t('chatBox.thread-type-claim')}
    ]

    const handleFilterChanged = (field: string, value: any) => {
        setFilter(prevState => {
            const newFilter = {...prevState, [field]: value}

            if (field === 'date') {
                if (value === '1week') {
                    newFilter.timestampFrom = moment().subtract(1, 'week').toISOString()
                    newFilter.timestampTo = moment().toISOString()
                } else if (value === '3weeks') {
                    newFilter.timestampFrom = moment().subtract(3, 'weeks').toISOString()
                    newFilter.timestampTo = moment().toISOString()
                } else if (value === '1month') {
                    newFilter.timestampFrom = moment().subtract(1, 'month').toISOString()
                    newFilter.timestampTo = moment().toISOString()
                } else if (value === '2months') {
                    newFilter.timestampFrom = moment().subtract(2, 'months').toISOString()
                    newFilter.timestampTo = moment().toISOString()
                } else if (value === '3months') {
                    newFilter.timestampFrom = moment().subtract(3, 'months').toISOString()
                    newFilter.timestampTo = moment().toISOString()
                } else if (!value) {
                    delete newFilter.timestampFrom;
                    delete newFilter.timestampTo;
                }

            }

            if (field === 'date' || field === 'isRead' || field === 'type') {
                onFilterThreads({
                    ...newFilter
                })
            }

            return {...newFilter}
        })
    }

    const handleScroll = (e: any) => {
        onThreadScroll(e)
    }

    const handleInputSearch = () => {
        onFilterThreads({
            ...filter, referenceCode: searchState
        })
    }

    const handleClearInputSearch = () => {
        setFilter({
            ...filter,
            referenceCode: undefined,
        });
        onFilterThreads({
            ...filter,
        })
    }


    return (
        <div className="box-chat-thread">
            <Spin spinning={threadLoading}>
                <div className={'pdbt10'}>
                    <div id="box-chat-thread__filter" className="box-chat-thread__filter">
                        <Input className={'box-chat-thread-input'}
                               size={'small'}
                               placeholder={t('chatBox.search-placeholder')}
                               suffix={<i
                                   className="fa-solid fa-magnifying-glass txt-color-gray4 txt-size-h9 cursor-pointer"
                                   onClick={handleInputSearch}/>}
                               allowClear={{
                                   clearIcon: <i className="fa-solid fa-x txt-color-gray4 txt-size-h9 cursor-pointer"
                                                 onClick={handleClearInputSearch}/>
                               }}
                               onChange={(event => setSearchState(event.target.value))}
                               onPressEnter={handleInputSearch}
                        />
                    </div>
                    <div id="box-chat-thread__filter" className="box-chat-thread__filter">
                        <Select
                            size={'small'}
                            placeholder={t('chatBox.filterTimestamp')}
                            value={filter.date}
                            onChange={value => handleFilterChanged('date', value)}
                            className={'box-chat-thread__filter__item mgr5'}
                            allowClear
                            //@ts-ignore
                            getPopupContainer={() => document.getElementById('box-chat-thread__filter')}
                        >
                            {dates.map(d => (
                                <Select.Option key={d.code} value={d.code}>{d.name}</Select.Option>
                            ))}
                        </Select>
                        <Select
                            size={'small'}
                            placeholder={t('chatBox.filter-thread-type')}
                            value={filter.type}
                            allowClear={true}
                            onChange={value => handleFilterChanged('type', value)}
                            className={'box-chat-thread__filter__item'}
                            //@ts-ignore
                            getPopupContainer={() => document.getElementById('box-chat-thread__filter')}
                        >
                            {threadType.map(s => (
                                <Select.Option key={s.name} value={s.code}>{s.name}</Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div id="box-chat-thread__filter" className="box-chat-thread__filter align-items-center">
                        <Button className={`status-filter-btn ${selectedAllStatus ? 'status-filter-btn_selected' : ''}`}
                                onClick={() => {
                                    setSelectedAllStatus(true);
                                    handleFilterChanged('isRead', '')
                                }}>
                            {t('chatBox.filterStatusAll')}
                        </Button>
                        <Button className={`mgl5 status-filter-btn ${!selectedAllStatus ? 'status-filter-btn_selected' : ''}`}
                                onClick={() => {
                                    setSelectedAllStatus(false);
                                    handleFilterChanged('isRead', 'false')
                                }}>
                            {t('chatBox.filterStatusUnRead')}
                        </Button>
                    </div>
                </div>
                <div className="box-chat-thread__threads" onScroll={handleScroll}>
                    {threads.map((item) => (
                        <ThreadItem key={item.id} thread={item}/>
                    ))}
                </div>
            </Spin>
        </div>
    )
}
