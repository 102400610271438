import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import lodash from "lodash";
import {Col, Row, Table} from "antd";
import {formatDateTime} from "../../../core/helpers/date-time";
import {formatMoneyBySuffix, formatMoneyByUnit} from "../../../core/helpers/string";
import { SkeletonTable, SkeletonTableColumnsType } from "../../SkeletonTable";
import EmptyDataFilter from "../../Empty/EmptyDataFilter";
interface TransactionProps{
    dataSource: any;
    loading: boolean
}
function Transactions(props: TransactionProps){
    const {t} = useTranslation();
    const {dataSource, loading} = props;
    const formatAmount = (amount: any, currency: string) => {
        let color = amount < 0 ? 'txt-color-red2' : 'txt-color-green';
        return <span
            className={color}>{`${amount > 0 ? '+' : ''}${formatMoneyByUnit(amount, currency)}`}</span>
    };
    const columns = [
        {
            title:  <span className={`mg-r-4 fsz-14 line-h-22 whitespace txt-color-gray4 whitespace`}>{t('orderDetail.transactionTime')}</span>,
            dataIndex: 'timestamp',
            key: 'timestamp',
            className:'txt-color-black txt-size-h7',
            render:(text: string,record:any)=> <span className={''}>{formatDateTime(record.timestamp)}</span>
        },

        {
            title:  <span className={`mg-r-4 fsz-14 line-h-22 whitespace txt-color-gray4 whitespace`}>{t('orderDetail.transactionAmount')}</span>,
            dataIndex: 'amount',
            key: 'amount',
            className: 'txt-size-h7',
            align: 'right' as 'right',
            render:(text: string, record:any)=> formatAmount(record.amount, record.currency)

        },
        {
            title: <span className={`mg-r-4 fsz-14 line-h-22 whitespace txt-color-gray4 whitespace`}>{t('orderDetail.transactionType')}</span>,
            dataIndex: 'type',
            key: 'type',
            className:'txt-color-black txt-size-h7',
            render:(text: string, record:any)=> <span>{lodash.get(record,'type.name','--')}</span>

        },
        {
            title: <span className={`mg-r-4 fsz-14 line-h-22 whitespace txt-color-gray4 whitespace`}>{t('orderDetail.transactionMemo')}</span>,
            dataIndex: 'memo',
            key: 'memo',
            className:'txt-color-black txt-size-h7',
            render:(text: string,record:any)=> <Col>
                <Row><span className={'txt-color-gray2'}>{t('orderDetail.transactionId',{value: record.txid})}</span></Row>
                    <span>{lodash.get(record,'memo','--')}</span>
            </Col>
        },
    ];
    return <SkeletonTable
    loading={loading}
    columns={columns as SkeletonTableColumnsType[]}
    className='order-transaction-list'
    rowCount={3}>
        <Table  className='order-transaction-list' dataSource={dataSource} columns={columns} pagination={false} locale={{emptyText: <EmptyDataFilter description={t('order.transactionsEmpty')} />}}/>
    </SkeletonTable>
}
export default Transactions;