import React from "react";
import {ProviderInterface} from "../../Interface/ProviderInterface";
import lodash from "lodash";

interface Props {
    provider: ProviderInterface|any
    customerUsername: string
    className?: string
}
export const Provider = (props: Props) => {
    const {provider, customerUsername, className} = props
    return (
        <> {
            provider ?
                <div className={`dpl-flex align-items-center supplier-component ${className ? className : ''}`}>
                    <img
                        className={'supplier-icon'}
                        src={lodash.get(provider, 'logo', '')}
                        alt={lodash.get(provider, 'name', '')}
                    />
                    <span
                        className={'mgl5 txt-size-h8 robotoregular txt-color-black supplier-name whitespace'}>{provider.name}</span>
                    <span className={'txt-color-black mgl5 mgr5'}>|</span>
                    <span
                        className={'txt-size-h8 robotoregular txt-color-black supplier-customer'}>{customerUsername}</span>
                </div> : null
        }
        </>
    )
}
