import React, {useState} from "react";
import {Button, Input, Modal} from "antd";
import {useTranslation} from "react-i18next";
import {IntegrationCreateCommand} from "../../Interface/ConnectShopInterface";

type ConnectStopModalProps = {
    market:string,
    showConnectModal:boolean,
    onCancel:()=>void,
    handleConnectShop:(body:IntegrationCreateCommand)=>void,
}

function ConnectShopModal(props:ConnectStopModalProps) {
    const {t} = useTranslation();
    const {onCancel,handleConnectShop,
        showConnectModal,market,
    } = props;

    const [data,setData] = useState<IntegrationCreateCommand>(
        {
            channel: market,
            name: "",
            redirectUrl: process.env.REACT_APP_REDIRECT_CONNECT_MARKET!,
        }
    );


    const handleSubmit = () => {
        handleConnectShop(data)
    }

    const handleEnableSubmit = () => {
        if(data.name.toString().length >=1) return true;
        else return false;
    }

    const validateInputName = (e:any) => {
        let temp = typeof e === 'string' ? e : '';
        // temp = temp.replace(/\s/g, '')
        temp = temp.trimStart();
        setData({
            ...data,
            name:temp,
        })
        return temp;
    }

    return (
        <Modal
            className={'connect-shop-modal'}
            title={t('connectMarket.connect-shop-modal-title',{shop:market})}
            visible={showConnectModal}
            onCancel={onCancel}
            closeIcon={<i className="fa-solid fa-xmark"/>}
            footer={[
                <Button className={'cancel'}
                        onClick={onCancel}
                >
                    <i className="fa-solid fa-xmark mgr10"/>
                    {t('connectMarket.cancel-connect-btn')}
                </Button>,
                <Button className={'submit'}
                        disabled={!handleEnableSubmit()}
                        onClick={handleSubmit}
                >
                    <i className="fa-solid fa-check mgr10"/>
                    {t('connectMarket.connect-btn')}
                </Button>
            ]}
            centered
        >
            <div className={'connect-shop-modal-input'}>
                <span>{t('connectMarket.shopDisplayName')}:</span>
                <Input
                    autoFocus
                    placeholder={t('connectMarket.dpName-placeholder')}
                    value={data.name}
                    onChange={(e) => validateInputName(e.target.value)}
                    onPressEnter={handleSubmit}
                />
            </div>
        </Modal>
    )
}

export default ConnectShopModal;
