import { Divider, Tooltip } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import { t } from 'i18next'
import React, { useCallback, useRef } from 'react'
import Skeleton from 'react-loading-skeleton'
import { UpdatableInterface } from '.'
import Services from '../../../components/Services'
import TruncateText from '../../../components/TruncateText'
import { formatNumber } from '../../../core/helpers/string'
import ShipmentInterface, { ServiceInterface } from '../../../Interface/ShipmentInterface'
import { shipmentRepository } from '../../../repositories/ShipmentRepository'
import M24ErrorUtils from '../../../utils/M24ErrorUtils'
import M24Notification from '../../../utils/M24Notification'

interface InformationProps {
	shipment?: ShipmentInterface
	expand: boolean
	updatable?: UpdatableInterface
	loading: boolean
}
const Information: React.FC<InformationProps> = (props) => {
	const { shipment, expand, updatable, loading } = props
	const refLabel = useRef<null | HTMLSpanElement>(null)
	const address1 = shipment?.address.address1 ? shipment?.address.address1 : '---'
	const displayAddress = shipment?.address.display ? shipment.address.display : '---'
	const deliveryAddress = `${address1}, ${displayAddress}`
	const refCustomerCode = shipment?.refCustomerCode ? shipment.refCustomerCode : '---'
	const refShipmentCode = shipment?.refShipmentCode ? shipment?.refShipmentCode : '---'
	const dimensionalWeight = `${shipment?.dimensionalWeight ? `${formatNumber(shipment?.dimensionalWeight)} kg` : '---'}`
	const volumetric = `${shipment?.volumetric ? `${formatNumber(shipment?.volumetric)} cm3` : '---'}`
	const employeeNote = shipment?.employeeNote ? shipment?.employeeNote : '---'
	const personalNote = shipment?.personalNote ? shipment?.personalNote : '---'
	const receivingWarehouseAddress = shipment?.receivingWarehouse?.address
		? shipment?.receivingWarehouse?.address
		: shipment?.receivingWarehouse?.recipientAddress
		? shipment?.receivingWarehouse?.recipientAddress
		: '---'
	const receiver = shipment?.address.receiver ? shipment.address.receiver : '---'
	const phone = shipment?.address.phoneNumber ? shipment.address.phoneNumber : '---'
	const originalReceipts =
		shipment?.originalReceipts && shipment.originalReceipts.length > 0 ? shipment.originalReceipts.join(', ') : '---'
	const updateShipment = useCallback(async (code, id, val, key, handleState) => {
		try {
			const res = await shipmentRepository.updateShipment(code, { [key]: val })
			const valueStr = res[key] ? res[key] : '---'
			handleState(valueStr)
			M24Notification.notifySuccess(t(`shipmentUpdate.updateSuccess`), '', '', 5)
		} catch (err) {
			M24ErrorUtils.showError(t, err)
		}
	}, [])

	return (
		<div className={`basic ${expand ? '' : 'basic-collapsed'}`}>
			<span className='basic-title'>{!loading ? t('shipment.deliveryAddress') : <Skeleton width={200} />}</span>
			{expand && (
				<>
					<span className='basic-item flex align-items-center'>
						<span className='basic-item__label'>{!loading ? `${t('shipment.receiver')}:` : <Skeleton />}</span>
						<span className='basic-item__content robotomedium'>{!loading ? receiver : <Skeleton width={200} />}</span>
					</span>
					<span className='basic-item flex align-items-center'>
						<span className='basic-item__label'>{!loading ? `${t('shipment.phone')}:` : <Skeleton />}</span>
						<span className='basic-item__content'>{!loading ? phone : <Skeleton width={110} />}</span>
					</span>
					<span className='align-items-baseline txt-color-black fsz-14 line-h-22'>
						<span className='basic-item__label'>
							{!loading ? `${t('shipment.receiverAddress')}:` : <Skeleton />}{' '}
						</span>
						{!loading ? deliveryAddress : <Skeleton />}
					</span>
					<Divider className='' />
					<div className='basic-number-data'>
						<div className='item align-items-center mg-r-10'>
							<span className='item__label'>{!loading ? `${t('shipment.yourCustomerCode')}:` : <Skeleton />}</span>
							{!loading ? (
								<TruncateText
									defaultValue={refCustomerCode}
									editable={true}
									keyUpdate='refCustomerCode'
									cb={updateShipment}
									className='basic-item__content'
								/>
							) : (
								<Skeleton width={150} />
							)}
						</div>
						<div className='item align-items-center mg-r-10'>
							<span className='item__label'>{!loading ? `${t('shipment.originalId')}:` : <Skeleton />}</span>
							{!loading ? (
								<TruncateText defaultValue={originalReceipts} className='basic-item__content' />
							) : (
								<Skeleton width={150} />
							)}
						</div>
						<div className='item  align-items-center'>
							<span className='item__label'>{!loading ? `${t('shipment.yourOrderCode')}:` : <Skeleton />}</span>
							{!loading ? (
								<TruncateText
									defaultValue={refShipmentCode}
									editable={true}
									keyUpdate='refShipmentCode'
									cb={updateShipment}
									className='basic-item__content'
								/>
							) : (
								<Skeleton width={150} />
							)}
						</div>
					</div>
					<Divider className='' />
					<div className='basic-number-data'>
						<div className='item'>
							<span className='item__label'>{!loading ? `${t('shipment.chargedWeight')}:` : <Skeleton />}</span>
							<span className='basic-item__content'>{!loading ? dimensionalWeight : <Skeleton width={150} />}</span>
						</div>
						<div className='item'>
							<span className='item__label'>{!loading ? `${t('shipment.volumetric')}:` : <Skeleton />}</span>
							<span className='basic-item__content'>{!loading ? volumetric : <Skeleton width={150} />}</span>
						</div>
						<div className='item item-services'>
							<span className='item__label'>{!loading ? `${t('shipment.services')}:` : <Skeleton />}</span>
							{!loading ? (
								<Services data={shipment?.services} className='fsz-14' dividerClass='fsz-14 line-h-22' />
							) : (
								<Skeleton width={200} />
							)}
						</div>
					</div>
					<Divider />
					<div className='basic-note'>
						<div className='item'>
							<span className='item__label'>
								<span>{!loading ? `${t('shipment.noteForOrder')}: ` : <Skeleton />}</span>
							</span>
							{!loading ? (
								<span className='dpl-flex txt-size-h7 txt-color-black align-items-baseline info-item width100pc'>
									<TruncateText defaultValue={employeeNote} />
								</span>
							) : (
								<Skeleton width={200} />
							)}
						</div>
						<div className='item' style={{maxWidth: `calc((100vw - 142px) * 8 / 20)`}}>
							<span className='item__label' ref={refLabel}>
								{!loading ? (
									<span className='robotoregular fsz-12 txt-color-black line-h-20'>
										{`${t('shipment.personalNote')}`}
										<Tooltip
											title={
												<span className='robotoregular fsz-10 txt-color-black line-h-20'>
													{t('shipment.personalNote')}
												</span>
											}
											color='#FBB739'>
											<i className='far fa-info-circle txt-color-gray4 txt-size-h8 cursor-pointer mg-l-3'></i>
										</Tooltip>
										:
									</span>
								) : (
									<Skeleton />
								)}
							</span>
							{!loading ? (
								<span
									className='dpl-flex txt-size-h7 txt-color-black align-items-baseline info-item width100pc'
									style={{ maxWidth: `calc(100% - ${refLabel.current?.offsetWidth}px)` }}>
									<TruncateText
										defaultValue={personalNote}
										editable={true}
										cb={updateShipment}
										keyUpdate='personalNote'
									/>
								</span>
							) : (
								<Skeleton width={200} />
							)}
							<br />
							
						</div>
					</div>
					<Divider />
					<div className='address-warehouse'>
						<div className='item'>
							<span className='item__label'>
								{!loading ? `${t('shipment.addressWarehouseChina')}: ` : <Skeleton />}
							</span>
							{!loading ? (
								<span className='txt-size-h7 txt-color-black align-items-baseline info-item width100pc'>
									<Paragraph
										className='mg-0 robotoregular txt-color-black  order-heading white-space-pre-wrap-impt'
										copyable={
											shipment?.receivingWarehouse
												? {
														text: receivingWarehouseAddress,
														icon: [
															<Tooltip
																title={
																	<span className='robotoregular fsz-12 txt-color-black line-h-20'>
																		Copy
																	</span>
																}
																color='#FBB739'>
																<i className='far fa-copy line-h-14 w-12' />
															</Tooltip>,
															<Tooltip
																title={
																	<span className='robotoregular fsz-12 txt-color-black line-h-20'>
																		Copied
																	</span>
																}
																color='#FBB739'>
																<i className='far fa-check fsz-12 line-h-14 w-12' />
															</Tooltip>,
														],
														tooltips: [false, false],
												  }
												: false
										}>
										{receivingWarehouseAddress}
									</Paragraph>
								</span>
							) : (
								<Skeleton />
							)}
						</div>
					</div>
				</>
			)}
		</div>
	)
}

export default Information

