import { RedoOutlined } from '@ant-design/icons'
import { Avatar, Button, Card, Col, DatePicker, Divider, Input, Row, Select, Table } from 'antd'
import lodash from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import EmptyDataFilter from '../../components/Empty/EmptyDataFilter'
import MainLayout from '../../components/Layout'
import M24Pagination from '../../components/Pagination/M24Pagination'
import { SkeletonTable, SkeletonTableColumnsType } from '../../components/SkeletonTable'
import { TRANSACTION_DESCRIPTIONS } from '../../core/config'
import { formatDateTime } from '../../core/helpers/date-time'
import { formatMoneyBySuffix } from '../../core/helpers/string'
import { supplierRepository } from '../../repositories/SupplierRepository'
import M24ErrorUtils from '../../utils/M24ErrorUtils'
import { handleChangeDate, handleInputChange, handleInputChangeMany, linkOrder } from '../../utils/StringUtils'
import './styles.scss'

function Transactions() {
	const [showProgressBar, setShowProgressBar] = useState<boolean>(false)
	const { t } = useTranslation()
	let [searchParams, setSearchParams] = useSearchParams()
	const [accounts, setAccounts] = useState<any[]>([])
	const [transactionTypes, setTransactionTypes] = useState<any[]>([])
	const [accountSelected, setAccountSelected] = useState<any>()
	const [currency, setCurrency] = useState<any>()
	const [supplierConfig, setSupplierConfig] = useState<any>()
	const [filter, setFilter] = useState<any>({})
	const [pagination, setPagination] = useState<any>({})
	const [transactions, setTransactions] = useState<any[]>([])
	const [total, setTotal] = useState(0)
	const [showFilter, setShowFilter] = useState(true)
	const [expand, setExpand] = useState(false)
	const [, setLoadingTransactionTypes] = useState(false)
	const [isSkeleton, setIsSkeleton] = useState(false)

	useEffect(() => {
		getAccounts()
	}, [])

	useEffect(() => {
		setExpand(transactionTypes.length > 0 ? false : true)
	}, [transactionTypes.length])

	const getData = (connectionId: any, filter?: any) => {
		setShowProgressBar(true)
		setIsSkeleton(true)
		setSearchParams(filter)
		supplierRepository
			.getTransactionsByAccount(connectionId, filter)
			.then((response) => {
				let currentPage = lodash.get(response, 'headers.x-page-number')
				let total = lodash.get(response, 'headers.x-total-count')
				setTotal(Number(total))
				let items: any = []
				response.data.map((item: any) => {
					if (!item.purchaseUnits || (item.purchaseUnits && item.purchaseUnits.length === 0))
						items.push({ ...item, rowSpan: 1, txid: item.id })
					else {
						item.purchaseUnits.map((x: any, index: number) => {
							let temp = lodash.cloneDeep(x)
							delete temp.purchaseUnits
							if (index === 0) temp.rowSpan = item.purchaseUnits.length
							else temp.rowSpan = 0
							temp.id = item.id
							temp.memo = x.memo
							temp.amount = x.amount
							temp.balanceAfter = item.balanceAfter
							temp.transactionType = x.customType
							items.push(temp)
						})
					}
				})
				setTransactions(items)
				setPagination({
					current: parseInt(currentPage) + 1,
					hideOnSinglePage: true,
					total: parseInt(total),
					pageSize: parseInt(lodash.get(response, 'headers.x-page-size')),
				})
			})
			.catch((err) => {
				// M24ErrorUtils.showError(t, err);
			})
			.finally(() => {
				setShowProgressBar(false)
				setTimeout(() => setIsSkeleton(false), 2000)
			})
	}
	const getAccounts = () => {
		setShowProgressBar(true)
		supplierRepository
			.getListAccount()
			.then((res) => {
				let temp = res.filter((x: any) => x.status === 'CONNECTED')
				if (temp && temp.length > 0) {
					let item = temp.find((x: any) => x.defaultConnection)
					if (!item) item = temp[0]
					setAccounts(temp)
					setAccountSelected(item)
					let currencyItem = JSON.parse(item.provider.currency)
					let redirectUrl = JSON.parse(item.provider.redirectUrl)
					setCurrency(currencyItem)
					setSupplierConfig(redirectUrl)
					if (item) {
						let types = searchParams.get('types')
						let query = searchParams.get('query')
						let timestampFrom = searchParams.get('timestampFrom')
						let timestampTo = searchParams.get('timestampTo')
						let page = searchParams.get('page')
						let size = searchParams.get('size')
						let temp: any = {}
						if (types) temp.types = types
						if (query) temp.query = query
						if (timestampFrom) temp.timestampFrom = timestampFrom
						if (timestampTo) temp.timestampTo = timestampTo
						if (page) temp.page = parseInt(page)
						if (size) temp.size = parseInt(size)
						setFilter(temp)
						getData(item.id, temp)
						getTransactionTypes(item.id)
					}
				}
			})
			.catch((err) => {
				M24ErrorUtils.showError(t, err)
			})
			.finally(() => setShowProgressBar(false))
	}
	const getTransactionTypes = (id: any) => {
		setLoadingTransactionTypes(true)
		supplierRepository
			.getTransactionTypes(id)
			.then((res) => {
				setTransactionTypes(res)
			})
			.catch((err) => {})
			.finally(() =>
				setTimeout(() => {
					setShowProgressBar(false)
					setLoadingTransactionTypes(false)
				}, 2000)
			)
	}
	/**
	 *
	 * @param amount
	 * @returns {*}
	 */
	const formatAmount = (amount: any, showPlus?: boolean) => {
		let color = amount < 0 ? 'txt-color-pink4' : 'txt-color-green'
		return (
			<span className={color}>{`${showPlus && amount > 0 ? '+' : ''}${formatMoneyBySuffix(
				amount,
				lodash.get(currency, 'prefix', ''),
				lodash.get(currency, 'suffix', '')
			)}`}</span>
		)
	}
	const handleChangeAccount = (value: any) => {
		let temp = accounts.find((x) => x.id === value)
		setAccountSelected(temp)
		let currencyItem = JSON.parse(temp.provider.currency)
		setCurrency(currencyItem)
		let redirectUrl = JSON.parse(temp.provider.redirectUrl)
		setSupplierConfig(redirectUrl)
		setFilter({})
		getData(value)
		getTransactionTypes(value)
	}
	const onPageChange = (filter: any) => {
		setFilter(filter)
		let temp = lodash.cloneDeep(filter)
		if (filter && filter.page && filter.page > 0) {
			temp.page = temp.page - 1
		}
		getData(accountSelected.id, temp)
	}

	const onChangeFilterMany = (key: string, isCheck: boolean, code: string) => {
		const temp = handleInputChangeMany(key, code, filter)
		setFilter(temp)
	}
	const onChange = (key: string, value: any) => {
		let temp = handleInputChange(key, value, filter)
		setFilter(temp)
	}
	const onChangeDate = (date: any, key: string) => {
		let temp = handleChangeDate(key, date, filter)
		setFilter(temp)
	}
	const resetFilter = () => {
		setFilter({})
		getData(accountSelected.id, {})
	}
	
	const columns = [
		{
			title: <span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>{t('transactions.createdAt')}</span>,
			dataIndex: 'timestamp',
			key: 'timestamp',
			width: 150,
			render: (date: any, record: any) => {
				const obj = {
					children: (
						<Col>
							<Row>
								<span className='_package-created-at'>{formatDateTime(date)}</span>
							</Row>
							<Row>
								<span className='_id txt-color-gray2 fsz-12 line-h-20'>{lodash.get(record, 'txid', '---')}</span>
							</Row>
						</Col>
					),
					props: {
						rowSpan: 0,
					},
				}
				obj.props.rowSpan = record.rowSpan
				return obj
			},
		},
		{
			title:  <span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>{t('transactions.type')}</span>,
			dataIndex: 'transactionType',
			key: 'transactionType',
			width: 150,
			render: (value: any, record: any) => {
				let type = transactionTypes.find((x) => x.code === value.toLowerCase())
				return <span className=''>{lodash.get(type, 'name', value)}</span>
			},
		},
		{
			title: <span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>{t('transactions.memo')}</span> ,
			dataIndex: 'memo',
			className: '_transaction-memo whitespace-initial txt-color-black txt-color-black',
			width: 300,
			key: 'memo',
			render: (type: any, record: any) => (
				<span
					dangerouslySetInnerHTML={{
						__html: linkOrder(lodash.get(record, 'memo', '---'), supplierConfig),
					}}></span>
			),
		},
		{
			title: <span className={'pdr15 fsz-14 line-h-22 robotomedium txt-color-gray4'}>{t('transactions.amount')}</span>,
			className: '_sub-transaction-amount txt-right',
			dataIndex: 'amount',
			key: 'amount',
			width: 150,
			render: (value: any, record: any) => (
				<Row className={`dpl-flex justify-content-end pdt5 pdbt5 pdr15`}>
					<span className='robotomedium fsz-14 line-h-22'>{formatAmount(record.amount, true)}</span>
				</Row>
			),
		},
		{
			title: <span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>{t('transactions.balanceAfter')}</span>,
			dataIndex: 'balanceAfter',
			className: '_transaction-balance txt-right',
			key: 'balanceAfter',
			width: 150,
			render: (amount: any, record: any) => {
				const obj = {
					children: <span className={'flr'}>{formatAmount(amount)}</span>,
					props: { rowSpan: 0 },
				}
				obj.props.rowSpan = record.rowSpan
				return obj
			},
		},
	]

	return (
		<MainLayout
			title={t('menu.transactions')}
			showProgressBar={showProgressBar}
			descriptions={{
				title: t('transactions.pageTitle'),
				descriptions: t('transactions.descriptions'),
				className: 'box-shadow-descriptions',
				pageCode: TRANSACTION_DESCRIPTIONS,
				hasDescription: true,
			}}
			breadcrumbs={{ total }}>
			<Card className={'border-card-header pd-12 filter-card mg-bt-12'}>
				<div className='flex justify-content-between align-items-center'>
					<span className={'fsz-16 txt-color-black robotomedium line-h-24'}>{t('transactions.filterTitle')}</span>
					<i
						className={`fa-solid fa-angle-${!showFilter ? 'down' : 'up'} cursor-pointer`}
						onClick={() => setShowFilter(!showFilter)}></i>
				</div>
				{showFilter && (
					<>
						<Row align='middle' gutter={[16, 8]} className={`mg-t-12 form-filter ${expand ? 'mg-bt-12' : ''}`}>
							{transactionTypes.length > 0 && (
								<Col span={24} className='dpl-flex align-items-center mg-bt-9'>
									<span className={`dpl-flex align-items-baseline flex-wrap`}>
										<span className='robotoregular fsz-14 line-h-22 whitespace txt-color-black mg-r-6'>
											{`${t('transactions.type')}:`}
										</span>
										{transactionTypes.map(({ code, name }: any) => {
											let isSelect = false
											if (filter?.types?.indexOf(code) >= 0) isSelect = true
											return (
												<span
													className={isSelect ? 'status-package active' : 'status-package'}
													key={code}
													onClick={() => {
														onChangeFilterMany('types', !isSelect, code)
													}}>
													{name}
												</span>
											)
										})}
									</span>
								</Col>
							)}
							{expand && (
								<>
									<Col span={8} className='dpl-flex align-items-center'>
										<span className={'txt-size-h7 txt-color-black whitespace robotoregular mg-r-4'}>
											{t('transactions.account')}:
										</span>
										<Select
											placeholder={t('transactions.selectAccount')}
											value={lodash.get(accountSelected, 'id', undefined)}
											className={'width100pc border-radius4'}
											filterOption={(input, option: any) => {
												return (
													JSON.stringify(option?.props?.children)
														.toLowerCase()
														.indexOf(input.toLowerCase()) >= 0
												)
											}}
											onChange={handleChangeAccount}>
											{accounts.map((x) => {
												return (
													<Select.Option value={x.id} key={x.id}>
														<Row className={'dpl-flex align-items-center line-h-20'}>
															<Avatar
																size={12}
																className='bd-none-impt'
																src={lodash.get(x, 'provider.logo')}></Avatar>
															<span className={'mgl5 txt-color-black fsz-12 robotoregular'}>
																{lodash.get(x, 'provider.name')}
															</span>
															<Divider type={'vertical'}></Divider>
															<span className={'txt-color-black fsz-12 robotoregular'}>
																{lodash.get(x, 'name')}
															</span>
														</Row>
													</Select.Option>
												)
											})}
										</Select>
									</Col>
									<Col span={8} className='dpl-flex align-items-center'>
										<span className={'txt-size-h7 txt-color-black whitespace robotoregular mg-r-4'}>
											{t('transactions.code')}:
										</span>
										<Input
											className={'width100pc py-3 border-radius4 h-32'}
											value={lodash.get(filter, 'query', '')}
											onChange={(e) => onChange('query', e.target.value)}
											onPressEnter={() => getData(accountSelected.id, { ...filter, page: 0 })}></Input>
									</Col>
									<Col span={8} className='dpl-flex align-items-center'>
										<span className={'txt-size-h7 txt-color-black whitespace robotoregular mg-r-4'}>
											{t('transactions.createdAt')}:
										</span>
										<DatePicker
											showTime={{
												format: 'HH:mm',
												defaultValue: moment('00:00:00', 'HH:mm'),
											}}
											value={filter.timestampFrom ? moment(filter.timestampFrom) : null}
											onChange={(date) => onChangeDate(date, 'timestampFrom')}
											format={'HH:mm DD-MM-YYYY'}
											placeholder={t('transactions.from')}
											suffixIcon={<i className='far fa-calendar-alt'></i>}
											className={'width100pc py-3 border-radius4 mg-r-4 h-32'}
										/>
										<DatePicker
											showTime={{
												format: 'HH:mm',
												defaultValue: moment('23:59:59', 'HH:mm'),
											}}
											value={filter.timestampTo ? moment(filter.timestampTo) : null}
											onChange={(date) => onChangeDate(date, 'timestampTo')}
											format={'HH:mm DD-MM-YYYY'}
											placeholder={t('transactions.to')}
											suffixIcon={<i className='far fa-calendar-alt'></i>}
											className={'width100pc py-3 border-radius4 h-32'}
										/>
									</Col>
								</>
							)}
						</Row>
						<Row
							className={`dpl-flex align-items-center justify-content-${
								transactionTypes.length > 0 ? 'between' : 'end'
							}`}>
							{transactionTypes.length > 0 && (
								<span
									className='flex align-items-center cursor-pointer fsz-12'
									onClick={() => setExpand(!expand)}>
									<i className={`mg-r-6 fa-solid fa-angle-${expand ? 'up' : 'down'}`}></i>
									<span className='line-h-20 txt-color-black'>
										{expand ? t('orders.filterCollapse') : t('shipments.expandFilter')}
									</span>
								</span>
							)}
							<div className='flex align-items-center'>
								{!lodash.isEmpty(filter) && (
									<Button
										className='clean-filter robotoregular txt-color-gray4 fsz-12 line-h-20 dpl-flex align-items-center
											flex-basis-btn-filter cursor-pointer bd-none mg-r-12'
										onClick={resetFilter}
										icon={<RedoOutlined className='mg-r-4' />}>
										<span className='mg-0'>{t('filter.refresh')}</span>
									</Button>
								)}
								<Button
									onClick={() => getData(accountSelected.id, { ...filter, page: 0 })}
									className='robotoregular txt-color-white bg-color-blue7 fsz-14 line-h-22 bd-none border-radius4
								px-12 py-5 flex-basis-btn-filter btn-df btn-df-secondary-bg-blue'>
									{t('filter.btSearch')}
								</Button>
							</div>
						</Row>
					</>
				)}
			</Card>
			<Card className={'transaction-card'}>
				<Row className='dpl-flex align-items-center pd-bt-12'>
					<h3 className='robotomedium  mg-0 fsz-16 line-h-24 txt-color-black mg-r-4'>{t('transactions.list')}</h3>
					<span
						className={`${
							total > 0 ? 'bg-color-primary txt-color-white' : 'bg-color-gray-badge txt-color-gray4'
						} border-radius20  fsz-12 line-h-20 px-8`}>
						{total}
					</span>
				</Row>
				{console.log('showProgressBar', showProgressBar, 'isSkeleton', isSkeleton)}
				<SkeletonTable
					loading={showProgressBar || isSkeleton}
					columns={columns as SkeletonTableColumnsType[]}
					className='main-table main-table-orange-hovered'
					rowCount={transactions.length ?? 3}>
					<Table
						rowKey={(record) => `${record.id}${record.amount}`}
						dataSource={transactions}
						locale={{
							emptyText: (
								<EmptyDataFilter
									description={
										!searchParams.get('types') &&
										!searchParams.get('query') &&
										!searchParams.get('timestampTo') &&
										!searchParams.get('timestampFrom')
											? t('transactions.empty')
											: t('transactions.filterEmpty')
									}
								/>
							),
						}}
						columns={columns}
						pagination={false}
						className='main-table main-table-orange-hovered'
					/>
				</SkeletonTable>
				{transactions && transactions.length > 0 && (
					<M24Pagination
						onPageChange={onPageChange}
						filter={filter}
						pagination={pagination}
						className='mg-bt-0 mg-t-12'
					/>
				)}
			</Card>
		</MainLayout>
	)
}

export default Transactions

