import { Tooltip } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import './truncate-text.style.scss';
interface TruncateProps {
	defaultValue?: any
	className?: string
	editable?: boolean
	id?: string
	keyUpdate?: string
	cb?: (code: string, id?:string, val?: string, key?:string, handleState?: (val: string) => void) => void
}

const TruncateText: React.FC<TruncateProps> = (props) => {
	const { defaultValue, editable, className, id , cb, keyUpdate } = props
	const [editing, setEditing] = useState(false)
	const [value, setValue] = useState(defaultValue)
	const [expand, setExpand] = useState(false);
	const [counter, setCounter] = useState(0);
	
	const {t} = useTranslation()
	useEffect(() => {
		setValue(defaultValue)
	}, [defaultValue])
	let { code } = useParams()

	const handleEditInline = (val: string) => {
		if(cb) {
			cb(code!,id, val, keyUpdate, (val: string) => setValue(val))
		}
		setEditing(false)
	}

	const typoExpandHandler = () => {
		setExpand(true)
		setCounter(!expand ? counter + 0 : counter + 1)
		
	  };
	
	const typoCloseHandler = () => {
		setExpand(false)
		setCounter(!expand ? counter + 0 : counter + 1)
	  };

	return (
		<div className={`${expand ? 'flex flex-col' : ''} width100pc truncate-text ${className ? className : ''}`} key={counter}>
			<Paragraph
				id='my-edit'
				className={`${expand ? 'mg-bt-2' : ''} ${editing ? 'editing' : ''} flex items-center mg-bt-0 width100pc ${!expand ? 'whitespace-nor-impt' : ''} ${
					className ? className : ''
				}`}
				editable={
					editable
						? {
								icon: (
									<span className={'mg-0 _note-icon-custom'} onClick={() => setEditing(true)}>
										<i className='far fa-edit txt-color-orange txt-size-h8'></i>
									</span>
								),
								tooltip: false,
								editing: editing,
								onChange: handleEditInline,
								onCancel: () => setEditing(false),
								autoSize: { minRows: 1, maxRows: 3 },
						  }
						: false
				}
				ellipsis={{
					rows: 1,
					expandable: true,
					symbol: (
						<Tooltip placement='topRight' title={<span className='text-reg'>{value}</span>} color='#FBB739'>
							<i className='far fa-info-circle cursor-pointer' style={{ color: '#FBB739' }}></i>
						</Tooltip>
					),
					onExpand: typoExpandHandler
				}}>
				{!editing ? value : value === '---' ? '' : value}
			</Paragraph>
			{expand && <div onClick={typoCloseHandler} className='whitespace txt-right fsz-12 line-h-20 cursor-pointer txt-color-primary collapse-icon'><i className="fa-solid fa-angle-up mg-r-6"></i>{t('shipment.collapse')}</div>}
		</div>
	)
}

export default TruncateText