import { FC, useEffect } from 'react';
import { Modal, Form, Button, FormInstance } from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import { t } from 'i18next';
import { PriceInput } from './PriceInput';

interface ModalConfigAllProps {
  showProgressBar: boolean;
  isShowModalConfigAll: boolean;
  setIsShowModalConfigAll: (show: boolean) => void;
  onFinishFormConfigAll: (values: any) => void;
}

const ModalConfigAll: FC<ModalConfigAllProps> = ({
  showProgressBar,
  isShowModalConfigAll,
  setIsShowModalConfigAll,
  onFinishFormConfigAll,
}: ModalConfigAllProps) => {
  const [form]: [FormInstance] = Form.useForm();
  const price: string | null = useWatch('priceFomula', form);

  const closeConfigAllModal = () => {
    if (!showProgressBar) {
      setIsShowModalConfigAll(false);
    }
  };

  const onFinish = (values: any): void => {
    onFinishFormConfigAll(values);
    form.setFieldsValue({
      priceFomula: '',
    });
  };

  useEffect(() => {
    if (!isShowModalConfigAll) {
      return;
    }

    form.setFieldsValue({
      priceFomula: '',
    });
  }, [form, isShowModalConfigAll]);

  return (
    <Modal
      className="modal-common"
      title={t('price-config.modal-config')}
      visible={isShowModalConfigAll}
      onCancel={closeConfigAllModal}
      closeIcon={<i className="fal fa-times"></i>}
      footer={null}
      centered
    >
      <Form onFinish={onFinish} form={form}>
        <PriceInput
          label={t('price-config.price')}
          name="priceFomula"
          autoFocus
          disableInput={showProgressBar}
        />

        <div className="ant-modal-footer mgt16">
          <Button
            className="m24-button"
            onClick={closeConfigAllModal}
            key="cancel"
          >
            <i className="fal fa-times mgr10"></i>
            {t('button.cancel')}
          </Button>

          <Button
            className="m24-button"
            key="submit"
            type="primary"
            htmlType="submit"
            icon={<i className="fal fa-check mgr10"></i>}
            loading={showProgressBar}
            disabled={!price}
          >
            {t('button.submit')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export { ModalConfigAll };
