import { AxiosResponse } from 'axios';
import { API_BASE_URL, DEFAULT_PAGE_SIZE } from "../core/config";
import { httpConfig } from "../core/config/http";
import { url } from '../core/helpers/string';
import { Repository } from '../core/repositories/Repository';


export class CartRepository extends Repository {
    constructor() {
        super(httpConfig);
        this.setBaseURL(url(API_BASE_URL, ''));
    }

    public getProductCart = (filter:any): Promise<any> => {
        let limit = filter && filter.size? filter.size:DEFAULT_PAGE_SIZE;
        let offset=filter && filter.page? filter.page*limit:0;
        return this.http
            .get(`carts`, {params:{...filter, offset, limit, sort:'timestamp:desc'}})
            .then((response: AxiosResponse<any>) => {
                return response;
            });
    }
    public deleteMerchant = (merchantId: any) : Promise<any> => {
        return this.http
            .delete(`carts/merchants/${merchantId}`);
    }

    public deleteSku = (id: any) : Promise<any> => {
        return this.http
            .delete(`carts/skus/${id}`);
    }

    public updateQuantitySku = (skuId: any, value:number):Promise<any> => {
        return this.http
            .post(`carts/skus/${skuId}/update-quantity`,{quantity: value}).then((response: AxiosResponse<any>) => {
                return response.data;
            });
    }

    public updateSku = (skuId: any,body:any):Promise<any> => {
        return this.http
            .patch(`carts/skus/${skuId}`,body).then((response: AxiosResponse<any>) => {
                return response.data;
            });
    }

    public createDraftOrder = (body: any):Promise<any> => {
        return this.http
            .post(`draft-orders`,body).then((response: AxiosResponse<any>) => {
                return response.data;
            });
    }
    public updateDraftOrder = (draftId: string,body: any):Promise<any> => {
        return this.http
            .post(`draft-orders/${draftId}/items`,body).then((response: AxiosResponse<any>) => {
                return response.data;
            });
    }
    public getAddressList = ():Promise<any> => {
        return this.http.get(`addresses`).then((response:AxiosResponse<any>) => {
           return response.data;
        });
    }

    public createAddress = (body:any): Promise<any>=> {
        return this.http
            .post(`/addresses`,body).then((res:AxiosResponse) => {
                return res;
            })
    }
    public updateAddress = (id: any, body:any): Promise<any>=> {
        return this.http
            .patch(`/addresses/${id}`,body).then((res:AxiosResponse) => {
                return res;
            })
    }

    public deleteAddress = (id:string) : Promise<any> => {
        return this.http.delete(`/addresses/${id}`).then((response: AxiosResponse) => {
            return response;
        })
    }

    public createOrderMultiple = (commands:any) : Promise<any> => {
        return this.http.post(`/orders/create-list`,{commands: commands}).then((response:AxiosResponse) => {
            return response;
        })
    }

    public createOrderOneShop = (body:any) : Promise<any> => {
        return this.http.post(`/orders`,body).then((response:AxiosResponse) => {
            return response;
        })
    }

    public checkAddressSupport = (body:any): Promise<any>=> {
        return this.http
            .post(`/addresses/provider-support`,body).then((res:AxiosResponse) => {
                return res;
            })
    }
    public getFavoriteServices = ():Promise<any> => {
        return this.http.get(`/customer-services`).then((response:AxiosResponse<any>) => {
            return response.data;
        });
    }
    public updateFavoriteServices = (body: any):Promise<any> => {
        return this.http.post(`/customer-services/favorites`, body).then((response:AxiosResponse<any>) => {
            return response.data;
        });
    }

    public requestForPayment = (body: any) : Promise<any> => {
        return this.http.post(`/payment-requests/payment`,{...body, paymentProvider: "DIOR"}).then((response:AxiosResponse) => {
            return response.data;
        })
    }

    public getPaymentRequests = (): Promise<any> => {
		return this.http.get('/payment-requests').then((response: AxiosResponse<any>) => {
			return response.data
		})
	}

}
export const cartRepository: CartRepository = new CartRepository();
