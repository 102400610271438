import { Divider, Spin, Tooltip } from 'antd'
import React, { useCallback, useRef, useState } from 'react'
import lodash from 'lodash'
import { useTranslation } from 'react-i18next'
import Item from './Item'
import TruncateText from '../../TruncateText'
import { formatMoneyByUnit } from '../../../core/helpers/string'
import { orderRepository } from '../../../repositories/OrderRepository'
import M24Notification from '../../../utils/M24Notification'
import M24ErrorUtils from '../../../utils/M24ErrorUtils'
import Services from '../../Services'
import Skeleton from 'react-loading-skeleton'
interface OrderDetailProps {
	orderDetail: any
	expand: boolean
	loading: boolean
}

export const textFromData = (obj: any, key: string, empty: any) => {
	return lodash.isNil(lodash.get(obj, key, empty)) || lodash.isEmpty(lodash.get(obj, key, empty))
		? empty
		: lodash.get(obj, key, empty)
}

const Information: React.FC<OrderDetailProps> = ({ orderDetail, expand, loading }) => {
	const { t } = useTranslation()
	const refLabel = useRef<HTMLSpanElement | null>(null)
	const emptyTxt = '---'
	const orderNote = textFromData(orderDetail, 'employeeNote', emptyTxt)
	const personalNote = textFromData(orderDetail, 'personalNote', emptyTxt)
	const displayAddress = textFromData(orderDetail, 'address.display', emptyTxt)
	const [currency] = useState<string>(textFromData(orderDetail, 'currency', emptyTxt))
	const listServices = textFromData(orderDetail, 'services', [])
	const exchangeRateStr = `${formatMoneyByUnit(1, lodash.get(orderDetail, 'currency', emptyTxt))}
                          = ${formatMoneyByUnit(lodash.get(orderDetail, 'exchangeRate', ''), currency)}`
	const merchantNameStr = textFromData(orderDetail, 'merchantName', emptyTxt)
	const depositRateStr = `${
		lodash.isNull(lodash.get(orderDetail, 'emdPercent')) ? emptyTxt : lodash.get(orderDetail, 'emdPercent', emptyTxt)
	}%`
	const netWeight = lodash.isNil(lodash.get(orderDetail, 'netWeight')) ? emptyTxt : `${lodash.get(orderDetail, 'netWeight')} kg`
	const packingWeight = lodash.isNil(lodash.get(orderDetail, 'packagingWeight'))
		? emptyTxt
		: `${lodash.get(orderDetail, 'packagingWeight')} kg`

	const dimensionalWeight = lodash.isNil(lodash.get(orderDetail, 'dimensionalWeight'))
		? emptyTxt
		: `${lodash.get(orderDetail, 'dimensionalWeight')} kg`
	const chargedWeight = lodash.isNil(lodash.get(orderDetail, 'actualWeight'))
		? emptyTxt
		: `${lodash.get(orderDetail, 'actualWeight')} kg`

	const classes = 'flex-basis-gap-120 flex-shrink-0 mgr60 mgbt8'
	const classesWeight = 'flex-basis-gap-120 flex-shrink-0 mgr60 mgbt8'

	const updatePersonalNote = useCallback(
		async (code, id = '', val, key, handleState) => {
			try {
				const res = await orderRepository.updatePersonalNoteOfOrder(code, val)
				handleState(res.personalNote ? res.personalNote : '---')
				M24Notification.notifySuccess(t('orderDetails.updatedPersonalNoteOrder'), '', '', 5)
			} catch (err) {
				M24ErrorUtils.showError(t, err)
			}
		},
		[t]
	)
	return (
		<div
			className={`dpl-flex flex-col flex-basis-half flex-grow-0 ${
				!expand ? 'orderDetail-collapse__item h-fit-content' : ''
			}`}>
			<h5 className={`robotomedium txt-size-h5 line-h-24 mg-0 txt-color-black ${expand ? 'mg-bt-8' : ''}`}>
				{!loading ? t('orderDetail.info') : <Skeleton width={150} height={22} />}
			</h5>
			{expand && (
				<div className='dpl-flex flex-col'>
					<div className='dpl-flex flex-col mg-bt-7'>
						<Item
							label={`${t('orderDetail.receiver')}:`}
							content={<span className='robotomedium'>{lodash.get(orderDetail, 'address.fullname', '--')}</span>}
							className='mg-bt-8'
							loading={loading}
							widthContent={80}
							widthLabel={60}
						/>
						<Item
							label={`${t('orderDetail.phone')}:`}
							content={lodash.get(orderDetail, 'address.phone', '--')}
							className='mg-bt-8'
							loading={loading}
							widthContent={80}
							widthLabel={120}
						/>
						<span className={`${loading ? 'flex align-items-center' : ''} robotoregular width100pc line-h-20  txt-color-gray4 mg-r-3`}>
							{!loading ? `${t('orderDetail.address')}:` : <Skeleton width={120} height={22} className='mg-r-3'/>}
							<span className={`mg-l-3 txt-size-h7 txt-color-black ${loading ? 'flex-grow-1' : ''}`}>
								{!loading ? `${lodash.get(orderDetail, 'address.detail', '--')}, ${displayAddress}` : <Skeleton height={22}/>}
							</span>
						</span>
					</div>
					<Divider className='mg-0 mg-bt-7 divider-bd-color' />
					{/* <SellerInfo orderDetail={orderDetail} loading={loading} /> */}
					<div className='dpl-flex flex-wrap mg-bt-7'>
						<span className={`robotoregular width100pc align-items-baseline ${classes} line-h-20 txt-color-gray4 ${loading ? 'flex align-items-center' : ''}`}>
							{!loading ? `${t('orderDetail.seller')}:` : <Skeleton width={100} height={22} />}
							<span className={`mg-l-3 txt-size-h7 txt-color-black info-item width100pc ${loading ? 'flex-grow-1' : ''}`}>{!loading ? merchantNameStr : <Skeleton height={22} />}</span>
						</span>
						<Item label={`${t('orderDetail.depositRate')}:`} content={depositRateStr} className={classes} 
						loading={loading} widthLabel={100} containerClassContent='flex-grow-1'/>
						<Item label={`${t('orderDetail.exchangeRate')}:`}  content={exchangeRateStr} className={classes} widthLabel={100} 
						loading={loading} containerClassContent='flex-grow-1'/>
						<span className={'flex-basis-100pc robotoregular flex align-items-baseline'}>
							{!loading ? <span className='txt-color-gray4 fsz-12 line-h-22 whitespace mg-r-6'>{`${t(
								'orderDetail.service'
							)}:`}</span> : <Skeleton width={60} className='mg-r-3' height={22}/>}
							{!loading ? <Services data={listServices} className='fsz-14'/> : <Skeleton count={3} containerClassName='flex align-items-center flex-grow-1' className='mg-r-4' width={100} height={22}/>}
						</span>
					</div>
					<Divider className='mg-0 mg-bt-7 divider-bd-color' />
					{/* <Weight orderDetail={orderDetail} loading={loading} /> */}
					<div className='dpl-flex flex-wrap'>
						<Item
							label={`${t('orderDetail.chargedWeight')}:`}
							content={<span className='whitespace'>{chargedWeight}</span>}
							className={classesWeight}
							loading={loading}
							containerClassContent='flex-grow-1'
							widthLabel={100} 
						/>
						<Item
							label={`${t('orderDetail.netWeight')}:`}
							content={<span className='whitespace'>{netWeight}</span>}
							className={classesWeight}
							loading={loading}
							containerClassContent='flex-grow-1'
							widthLabel={100} 
						/>
						<Item
							label={`${t('orderDetail.weightConversion')}:`}
							content={<span className='whitespace'>{dimensionalWeight}</span>}
							className={classesWeight}
							loading={loading}
							containerClassContent='flex-grow-1'
							widthLabel={100} 
						/>
						<Item
							label={`${t('orderDetail.packingWeight')}:`}
							content={<span className='whitespace'>{packingWeight}</span>}
							className={classesWeight}
							loading={loading}
							containerClassContent='flex-grow-1'
							widthLabel={100} 
						/>
					</div>
					<Divider className='mg-0 mg-bt-7 divider-bd-color' />
					<Item
						label={<span className='mg-r-3'>{`${t('orderDetail.orderNote')}:`}</span>}
						content={<TruncateText defaultValue={orderNote} editable={false} />}
						loading={loading}
						containerClassContent='flex-grow-1'
							widthLabel={100} 
					/>
					<div className='flex items-center' style={{maxWidth: `calc((100vw - 142px) * 8 / 20)`}}>
						{!loading ? <span className='line-h-22 h-22 txt-nowrap txt-color-gray4 mg-r-3' ref={refLabel}>
							<span>{`${t('orderDetail.personalNote')}: `}</span>
							<Tooltip
								title={
									<span className='robotoregular fsz-12 txt-color-black line-h-20'>
										{t('orderDetail.noticePersonal')}
									</span>
								}
								color='#FBB739'>
								<i className='far fa-info-circle txt-color-gray4 txt-size-h8 cursor-pointer'></i>
							</Tooltip>
						</span> : <Skeleton width={100} height={22} className='mg-r-3'/>}
						{!loading ? <span className='dpl-flex txt-size-h7 txt-color-black align-items-baseline info-item width100pc' style={{ maxWidth: `calc(100% - ${refLabel.current?.offsetWidth}px)` }}>
							<TruncateText defaultValue={personalNote} editable={true} cb={updatePersonalNote} />
						</span> : <Skeleton containerClassName='flex-grow-1' height={22}/>}
					</div>
				</div>
			)}
		</div>
	)
}

export default Information
