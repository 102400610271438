import { Button, notification } from 'antd'
import lodash from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ClaimFilter from '../../components/Claim/ClaimFilter'
import ClaimList from '../../components/Claim/ClaimList'
import MainLayout from '../../components/Layout'
import M24Pagination from '../../components/Pagination/M24Pagination'
import { DEFAULT_PAGE_SIZE } from '../../core/config'
import { ClaimInterface } from '../../Interface/ClaimInterface'
import { categoryRepository } from '../../repositories/CategoryRepository'
import { claimRepository } from '../../repositories/ClaimRepository'
import { supplierRepository } from '../../repositories/SupplierRepository'

function Claims() {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [loadingClaim, setLoadingClaim] = useState(false)
	const [claimData, setClaimData] = useState<ClaimInterface[]>([])
	const [pagination, setPagination] = useState<any>({})
	const [selectedAccount, setSelectedAccount] = useState<any>({})
	const [providerAccounts, setProviderAccounts] = useState<any[]>([])
	const [ticketStatuses, setTicketStatuses] = useState<any[]>([])
	const [ticketSolutions, setTicketSolutions] = useState<any[]>([])
	const [total, setTotal] = useState<number>(0)
	const [searchParams, setSearchParams] = useSearchParams()
	const [isSkeleton, setIsSkeleton] = useState(true)

	const openCreateTicketForm = () => {
		navigate(`/claims/create`)
	}

	const handleChangePage = (filter: any) => {
		const newFilter = {
			...filter,
			offset: (filter.page - 1) * filter.pageSize,
			limit: filter.pageSize,
		}
		setSearchParams({
			...Object.fromEntries(searchParams.entries()),
			...newFilter,
		})
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	React.useEffect(() => {
		const getProviderAccounts = () => {
			let providerCode = searchParams.get('providerCode')
			const accountCode = searchParams.get('accountCode')
			supplierRepository.getListAccount().then((response) => {
				let temp = response.filter((x: any) => x.status === 'CONNECTED')
				setProviderAccounts(temp)
				if (temp && temp.length > 0) {
					let defaultAcc = temp.find((x: any) => x.defaultConnection)
					if (!providerCode) {
						if (defaultAcc) setSelectedAccount(defaultAcc)
						else setSelectedAccount(temp[0])
					} else {
						setSelectedAccount(
							temp.find(
								(x: any) =>
									lodash.get(x, 'provider.code') === providerCode && lodash.get(x, 'name') === accountCode
							)
						)
					}
				}
			})
		}
		getProviderAccounts()
	}, [searchParams])

	React.useEffect(() => {
		if (selectedAccount.id) {
			const getTicketStatuses = () => {
				claimRepository.getClaimStatuses(selectedAccount?.provider.code).then((response) => {
					setTicketStatuses(response)
				})
			}
			getTicketStatuses()
			const getTicketSolutions = () => {
				categoryRepository.getSolutionsOfClaim(selectedAccount?.provider.code).then((response) => {
					setTicketSolutions(response)
				})
			}
			getTicketSolutions()
		}
	}, [selectedAccount])

	React.useEffect(() => {
		if (selectedAccount.id) {
			const accountCode = searchParams.get('accountCode') || selectedAccount.name
			const providerCode = searchParams.get('providerCode') || selectedAccount?.provider?.code
			const ticketType = searchParams.get('ticketType')
			const getTickets = (filter?: any) => {
				setLoadingClaim(true)
				setIsSkeleton(true)

				filter = {
					...filter,
					ticketType,
				}
				claimRepository
					.getClaims(providerCode, accountCode, filter)
					.then((response) => {
						let currentPage = lodash.get(response, 'headers.x-page-number')
						let total = lodash.get(response, 'headers.x-total-count')
						setTotal(total)
						setClaimData(response.data)
						setPagination({
							current: parseInt(currentPage) + 1,
							hideOnSinglePage: true,
							total: parseInt(total),
							pageSize: parseInt(lodash.get(response, 'headers.x-page-size')),
						})
					})
					.catch(() => {
						notification.error({
							message: t('tickets.error_load_list'),
						})
						setTotal(0)
						setClaimData([])
						setPagination({
							current: 1,
							hideOnSinglePage: true,
							total: 0,
							pageSize: 25,
						})
					})
					.finally(() => {
						setLoadingClaim(false)
						setTimeout(() => setIsSkeleton(false), 2000)
					})
			}
			getTickets(Object.fromEntries(searchParams.entries()))
		}else {
			setTimeout(() => setIsSkeleton(false), 2000)
		}
	}, [searchParams, selectedAccount, t])

	const handleSelectAccount = (account: any) => {
		setSelectedAccount(account)
	}

	return (
		<MainLayout title={t('menu.tickets')} breadcrumbs={{ total }} showProgressBar={loadingClaim}>
			<div className={'ticket-container'}>
				<ClaimFilter
					providerAccounts={providerAccounts}
					selectedAccount={selectedAccount}
					ticketStatuses={ticketStatuses}
					ticketSolutions={ticketSolutions}
					handleSelectAccount={handleSelectAccount}
					loading={loadingClaim}
					handleSkeleton={(val: boolean) => setIsSkeleton(val)}
				/>
				<div className={'border-card-header border-radius4 pd12'}>
					<div className={'dpl-flex align-item-center justify-content-between'}>
						<span className={'dpl-flex'}>
							<span className={'fsz-16 robotomedium txt-color-black self-center txt-capitalize'}>
								{t('menu.tickets')}
							</span>
							<span className={!total ? 'c-badge-no-data' : 'c-badge'}>
								<span className={!total ? 'c-badge-num-no-data' : 'c-badge-num'}>{total || 0}</span>
							</span>
						</span>
						<Button className={'create-ticket-btn'} onClick={openCreateTicketForm}>
							<i className='far fa-plus pdr5' />
							<span className={'txt-size-h7 robotoregular txt-capitalize'}>{t('tickets.createTickets')}</span>
						</Button>
					</div>
					<ClaimList
						ticketStatuses={ticketStatuses}
						providerAccounts={providerAccounts}
						claimData={claimData}
						loadingClaim={loadingClaim}
						isSkeleton={isSkeleton}
					/>
					{claimData && claimData.length >= 1 && (
						<M24Pagination
							filter={{
								...Object.fromEntries(searchParams.entries()),
								size: searchParams.has('size')
									? parseInt(searchParams.get('size') || DEFAULT_PAGE_SIZE.toString())
									: DEFAULT_PAGE_SIZE,
							}}
							pagination={pagination}
							onPageChange={handleChangePage}
							className={'mgt10'}
						/>
					)}
				</div>
			</div>
		</MainLayout>
	)
}

export default Claims
