import { DashOutlined, LogoutOutlined } from '@ant-design/icons'
import { Image, Layout, Menu, MenuProps, Row, Tooltip } from 'antd'
import lodash, { map } from 'lodash'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { TENANT_KEY } from '../../core/config'
import Logo from '../../resources/images/gobiz.png'
import { localStorageRead } from '../../utils/LocalStorageUtils'
const { Sider } = Layout

const { ItemGroup, Item } = Menu

interface SidebarProps {
	logoutHandler: () => void
	toggleHandler: () => void
	specificToggle: (val: boolean) => void
	collapsed: boolean
	activeKey: string
	currentConfigServices: any
}

interface ChildrenOfMenuItem {
	key?: string
	icon: ReactNode
	toolTip: string
	children: string
	subs?: any
}

const Sidebar: React.FC<SidebarProps> = (props) => {
	const { collapsed, toggleHandler, activeKey, logoutHandler, currentConfigServices, specificToggle } = props
	const [, setCurrentConfigShowBalance] = useState()
	const ref = useRef<HTMLDivElement | null>(null)

	const navigate = useNavigate()
	const { t } = useTranslation()
	const [menu, setMenu] = useState<any>()

	type MenuItem = Required<MenuProps>['items'][number]

	useEffect(() => {
		let connectItems = [
			{
				key: 'market-connect',
				icon: <i className={`fa-regular fa-link ${activeKey === 'market-connect' ? 'txt-color-orange1' : 'txt-color-white'}`} />,
				tooltip: t('menu.connect-market'),
				label: <Link to={`/market-connect`}>{t('menu.connect-market')}</Link>,
			},
			{
				key: 'connect-supplier',
				icon: <i className={`fas fa-house-signal ${activeKey === 'connect-supplier' ? 'txt-color-orange1' : 'txt-color-white'}`} />,
				tooltip: t('menu.connectSupplier'),
				label: <Link to={`/connect-supplier`}>{t('menu.connectSupplier')}</Link>,
			},
		]

		let configItems = [
			{
				key: 'price-config',
				icon: <i className={`fa-solid fa-gear ${activeKey === 'price-config' ? 'txt-color-orange1' : 'txt-color-white'}`}></i>,
				tooltip: t('menu.price-config'),
				label: <Link to={`/price-config`}>{t('menu.price-config')}</Link>,
			},
		]

		let productItems = [
			{
				key: 'warehouse/stored-products',
				icon: <i className={`fa-solid fa-shop ${activeKey === 'warehouse/stored-products' ? 'txt-color-orange1' : 'txt-color-white'}`}></i>,
				label: <Link to={`/warehouse/stored-products`} >{t('menu.storedProductList')}</Link>,
				tooltip: t('menu.storedProductList'),
			},
			{
				key: 'shopping-cart',
				icon: <i className={`fas fa-shopping-cart ${activeKey === 'shopping-cart' ? 'txt-color-orange1' : 'txt-color-white'}`} />,
				label: <Link to={`/shopping-cart`}>{t('menu.shopping-cart')}</Link>,
				tooltip: t('menu.shopping-cart'),
			},
		]

		let financeItems = [
			{
				key: 'transactions',
				icon: <i className={`far fa-exchange ${activeKey === 'transactions' ? 'txt-color-orange1' : 'txt-color-white'}`} />,
				tooltip: t('menu.transactions'),
				label: <Link to={`/transactions`}>{t('menu.transactions')}</Link>,
			},
		]

		let deliveryItems = [
			{
				key: 'packages',
				icon: <i className={`fas fa-truck-loading ${activeKey === 'packages' ? 'txt-color-orange1' : 'txt-color-white'}`} />,
				tooltip: t('menu.packages'),
				label: <Link to={`/packages`}>{t('menu.packages')}</Link>,
			},
			{
				key: 'delivery',
				icon: <i className={`fas fa-hand-holding-box ${activeKey === 'delivery' ? 'txt-color-orange1' : 'txt-color-white'}`} />,
				label: <Link to={`/delivery`}>{t('menu.requestDelivery')}</Link>,
				tooltip: t('menu.requestDelivery'),
			},
			{
				key: 'delivery-notes',
				icon: <i className={`fas fa-list-alt ${activeKey === 'delivery-notes' ? 'txt-color-orange1' : 'txt-color-white'}`} />,
				label: <Link to={`/delivery-notes`}>{t('menu.delivery-notes')}</Link>,
				tooltip: t('menu.delivery-notes'),
			},
		]

		let ordersItems = [
			{
				key: 'orders',
				icon: <i className={`fas fa-paste ${activeKey === 'orders' ? 'txt-color-orange1' : 'txt-color-white'}`} />,
				tooltip: t('menu.orders'),
				label: <Link to={`/orders`}>{t('menu.orders')}</Link>,
			},
			{
				key: 'shipments',
				icon: <i className={`fa-regular fa-cart-flatbed-boxes ${activeKey === 'shipments' ? 'txt-color-orange1' : 'txt-color-white'}`}></i>,
				tooltip: t('menu.shipments'),
				label: <Link to={`/shipments`}>{t('menu.shipments')}</Link>,
			},
			{
				key: 'claims',
				icon: <i className={`fa-solid fa-message-exclamation ${activeKey === 'txt-color-orange1' ? 'txt-color-orange1' : 'txt-color-white'}`} />,
				tooltip: t('menu.tickets'),
				label: <Link to={`/claims`}>{t('menu.tickets')}</Link>,
			},
		]

		let gsaItems = [
			{
				icon: <i className='icons8-shopee' />,
				label: <Link to={`/pending-products/shopee`}>{t('menu.pending-product')}</Link>,
				key: 'pending-products/shopee',
				tooltip: 'Shopee',
			},
			{
				icon: <i className='icons8-shopee' />,
				label: <Link to={`/selling-products/shopee`}>{t('menu.selling-product')}</Link>,
				key: 'selling-products/shopee',
				tooltip: 'Shopee',
			},
		]

		if (!currentConfigServices?.enableGsa) {
			connectItems = connectItems.filter((item) => item.key !== 'market-connect')
			productItems = productItems.filter((item) => item.key !== 'warehouse/stored-products')
			configItems = configItems.filter((item) => item.key !== 'price-config')
			gsaItems = gsaItems.filter(
				(item) =>
					item.key !== 'warehouse/stored-products' &&
					item.key !== 'selling-products/shopee' &&
					item.key !== 'pending-products/shopee'
			)
		}
		if (!currentConfigServices?.enableOrder) {
			ordersItems = ordersItems.filter((item) => item.key !== 'orders')
		}
		if (!currentConfigServices?.enableShipment) {
			ordersItems = ordersItems.filter((item) => item.key !== 'shipments')
		}
		if (!currentConfigServices?.enableShipment && !currentConfigServices?.enableOrder) {
			connectItems = connectItems.filter((item) => item.key !== 'connect-supplier')
			productItems = productItems.filter((item) => item.key !== 'shopping-cart')
			financeItems = financeItems.filter((item) => item.key !== 'transactions')
			deliveryItems = deliveryItems.filter(
				(item) => item.key !== 'packages' && item.key !== 'delivery-notes' && item.key !== 'delivery'
			)
			ordersItems = ordersItems.filter((item) => item.key !== 'claims')
		}
		let items: MenuItem[] = [
			// ************************ PRODUCT ************************

			{
				title: t('menu.products'),
				key: 'products',
				label: collapsed
					? productItems?.length > 0 && (
							<>
								<div className={'menu-group-collapse'}>
									<DashOutlined className='txt-color-white' />
								</div>
								<div className='items-group collapsed'>
									{productItems?.map((item: any, idx: number) => {
										return (
											<Tooltip
												key={idx}
												title={item.tooltip}
												placement='right'
												overlayInnerStyle={{
													fontWeight: 500,
													color: '#1a1a1a',
													fontFamily: 'Roboto',
													fontSize: '12px',
												}}>
												<Link
													to={`/${item.key}`}
													key={item.key}
													className={`${activeKey === item.key ? 'bg-color-white' : 'bg-color-primary'} icon`}>
													{item.icon}
												</Link>
											</Tooltip>
										)
									})}
								</div>
							</>
					  )
					: productItems.length > 0 && t('menu.products'),
				children: productItems
			},

			// ************************ ORDERS ************************

			{
				title: t('menu.ordersGroup'),
				key: 'orders-group',
				children: ordersItems,
				label: collapsed ? (
					<>
						{ordersItems.length > 0 && (
							<div className={'menu-group-collapse'}>
								<DashOutlined className='txt-color-white' />
							</div>
						)}
						<div className='items-group collapsed'>
							{ordersItems?.map((item: any, idx: number) => {
								return (
									<Tooltip
										key={idx}
										title={item.tooltip}
										placement='right'
										overlayInnerStyle={{
											fontWeight: 500,
											color: '#1a1a1a',
											fontFamily: 'Roboto',
											fontSize: '12px',
										}}>
										<Link
											to={`/${item.key}`}
											key={item.key}
											className={`${activeKey === item.key ? 'bg-color-white icon-active' : 'bg-color-primary'} icon`}>
											{item.icon}
										</Link>
									</Tooltip>
								)
							})}
						</div>
					</>
				) : (
					t('menu.ordersGroup')
				),
			},
			// ************************ FINANCE ************************

			{
				title: t('menu.finance'),
				key: 'finance',
				children: financeItems,
				label: collapsed ? (
					<>
						<div className={'menu-group-collapse'}>
							<DashOutlined className='txt-color-white' />
						</div>
						<div className='items-group collapsed'>
							{financeItems?.map((item: any, idx: number) => {
								return (
									<Tooltip
										key={idx}
										title={item.tooltip}
										placement='right'
										overlayInnerStyle={{
											fontWeight: 500,
											color: '#1a1a1a',
											fontFamily: 'Roboto',
											fontSize: '12px',
										}}>
										<Link
											to={`/${item.key}`}
											key={item.key}
											className={`${activeKey === item.key ? 'bg-color-white' : 'bg-color-primary'} icon`}>
											{item.icon}
										</Link>
									</Tooltip>
								)
							})}
						</div>
					</>
				) : (
					t('menu.finance')
				),
			},

			// ************************ PACKAGES ************************

			{
				title: t('menu.packagesGroup'),
				key: 'delivery-group',
				children: deliveryItems,
				label: collapsed ? (
					<>
						{deliveryItems?.length > 0 && (
							<div className={'menu-group-collapse'}>
								<DashOutlined className='txt-color-white' />
							</div>
						)}
						<div className='items-group collapsed'>
							{deliveryItems?.map((item: any, idx: number) => {
								return (
									<Tooltip
										key={idx}
										title={item.tooltip}
										placement='right'
										overlayInnerStyle={{
											fontWeight: 500,
											color: '#1a1a1a',
											fontFamily: 'Roboto',
											fontSize: '12px',
										}}>
										<Link
											to={`/${item.key}`}
											key={item.key}
											className={`${activeKey === item.key ? 'bg-color-white' : 'bg-color-primary'} icon`}>
											{item.icon}
										</Link>
									</Tooltip>
								)
							})}
						</div>
					</>
				) : (
					t('menu.packagesGroup')
				),
			},

			// ************************ CONNECT ************************
			{
				label: collapsed
					? connectItems?.length > 0 && (
							<>
								<div className={'menu-group-collapse'}>
									<DashOutlined className='txt-color-white' />
								</div>
								<div className='items-group collapsed'>
									{connectItems?.map((item: any, idx: number) => {
										return (
											<Tooltip
												key={idx}
												title={item.tooltip}
												placement='right'
												overlayInnerStyle={{
													fontWeight: 500,
													color: '#1a1a1a',
													fontFamily: 'Roboto',
													fontSize: '12px',
												}}>
												<Link
													to={`/${item.key}`}
													key={item.key}
													className={`${activeKey === item.key ? 'bg-color-white' : 'bg-color-primary'} icon`}>
													{item.icon}
												</Link>
											</Tooltip>
										)
									})}
								</div>
							</>
					  )
					: connectItems?.length > 0 && t('menu.connect'),
				key: 'connect',
				children: connectItems,
			},

			// ************************ CONFIG ************************

			{
				label: collapsed
					? configItems?.length > 0 && (
							<>
								<div className={'menu-group-collapse'}>
									<DashOutlined className='txt-color-white' />
								</div>
								<div className='items-group collapsed'>
									{configItems?.map((item: any, idx: number) => {
										return (
											<Tooltip
												key={idx}
												title={item.tooltip}
												placement='right'
												overlayInnerStyle={{
													fontWeight: 500,
													color: '#1a1a1a',
													fontFamily: 'Roboto',
													fontSize: '12px',
												}}>
												<Link
													to={`/${item.key}`}
													key={item.key}
													className={`${activeKey === item.key ? 'bg-color-white' : 'bg-color-primary'} icon`}>
													{item.icon}
												</Link>
											</Tooltip>
										)
									})}
								</div>
							</>
					  )
					: configItems?.length > 0 && t('menu.config'),
				key: 'config',
				children: configItems,
			},

			// ************************ WAREHOUSE ************************

			{
				title: t('warehouse.warehouseTitle'),
				key: 'warehouse',
				children: [],
				// label: collapsed ? (
				// 	<>
				// 		{warhorseItems?.length > 0 && (
				// 			<div className={'menu-group-collapse'}>
				// 				<DashOutlined className='txt-color-white' />
				// 			</div>
				// 		)}

				// 		<div className='items-group collapsed'>
				// 			{warhorseItems?.map((item: any) => {
				// 				return (
				// 					<Tooltip
				// 						title={item.tooltip}
				// 						placement='right'
				// 						overlayInnerStyle={{
				// 							fontWeight: 500,
				// 							color: '#1a1a1a',
				// 							fontFamily: 'Roboto',
				// 							fontSize: '12px',
				// 						}}>
				// 						<Link
				// 							to={`/${item.key}`}
				// 							key={item.key}
				// 							className={`${activeKey === item.key ? 'bg-color-white' : 'bg-color-primary'} icon`}>
				// 							{item.icon}
				// 						</Link>
				// 					</Tooltip>
				// 				)
				// 			})}
				// 		</div>
				// 	</>
				// ) : (
				// 	t('warehouse.warehouseTitle')
				// ),
			},

			// /************************ LINKED_CHANEL ************************/

			{
				title: t('menu.linked-channels'),
				key: 'linked-channels',
				children: gsaItems,
				label: collapsed ? (
					<>
						{gsaItems?.length > 0 && (
							<div className={'menu-group-collapse'}>
								<DashOutlined className='txt-color-white' />
							</div>
						)}
						<Tooltip
							title={
								<div className='flex flex-col justify-content-between shopee'>
									{gsaItems &&
										gsaItems?.map((item, index) => (
											<Link
												key={index}
												to={`/${item.key}`}
												className={`${
													index !== gsaItems.length - 1 ? 'mg-bt-12' : ''
												} txt-color-black robotoregular shoppe-gsa-link`}>
												{item.label}
											</Link>
										))}
								</div>
							}
							placement='right'
							overlayInnerStyle={{
								backgroundColor: 'white',
								color: '#1a1a1a',
								fontFamily: 'Roboto',
								fontSize: '12px',
								padding: 12,
								width: '150px',
							}}
							overlayClassName='gsa-tooltip'>
							<div className='items-group collapsed'>
								{gsaItems?.length > 0 && (
									<Tooltip
										title={gsaItems[0].tooltip}
										placement='bottom'
										overlayInnerStyle={{
											fontWeight: 500,
											color: '#1a1a1a',
											fontFamily: 'Roboto',
											fontSize: '12px',
										}}
										overlayClassName='tooltip-custom'>
										<span
											key={gsaItems[0].key}
											className={`${activeKey === gsaItems[0].key ? 'bg-color-white' : 'bg-color-primary'} icon`}>
											{gsaItems[0].icon}
										</span>
									</Tooltip>
								)}
							</div>
						</Tooltip>
					</>
				) : (
					t('menu.linked-channels')
				),
			},
		]
		const showBalance = lodash.get(localStorageRead(TENANT_KEY), 'config.showBalance', '')
		if (!currentConfigServices?.enableShipment && !currentConfigServices?.enableOrder && !currentConfigServices?.enableGsa) {
			items = items.filter((item: any) => item?.key !== 'connect')
		}
		if (!currentConfigServices?.enableShipment && !currentConfigServices?.enableOrder) {
			items = items.filter(
				(item: any) =>
					item?.key !== 'products' && item?.key !== 'transactions' && item?.key !== 'orders' && item?.key !== 'delivery'
			)
		}

		if (!currentConfigServices?.enableGsa) {
			items = items.filter((item: any) => item?.key !== 'warehouse')
		}
		if (!currentConfigServices?.enableGsa) {
			items = items.filter((item: any) => item?.key !== 'linked-channels')
		}
		items = items.filter((item: any) => item?.children.length > 0)
		setCurrentConfigShowBalance(showBalance)
		const menu = !showBalance ? items.filter((item) => item?.key !== 'transactions') : items
		setMenu(menu)
	}, [
		activeKey,
		collapsed,
		currentConfigServices?.enableGeneral,
		currentConfigServices?.enableGsa,
		currentConfigServices?.enableOrder,
		currentConfigServices?.enableShipment,
		t,
	])
	return (
		<Sider
			collapsible
			collapsed={collapsed}
			onCollapse={toggleHandler}
			className={`sidebar-left ${collapsed ? 'collapsed' : 'expanded'}`}
			theme='light'
			trigger={<LogoutOutlined style={{ color: '#F59F32' }} />}
			collapsedWidth={56}
			width={240}
			onMouseLeave={() => specificToggle(true)}>
			{!collapsed ? (
				<Row align='middle' justify='space-between' className='logo-expand' ref={ref}>
					<Image
						preview={false}
						height={48}
						width={48}
						src={Logo}
						alt='ubox-logo'
						className='cursor-pointer'
						onClick={() => navigate('/')}
					/>
					<i className='fas fa-angle-double-left cursor-pointer txt-color-white' onClick={toggleHandler} />
				</Row>
			) : (
				<div
					className={'logo-collapse'}
					onMouseEnter={() => {
						specificToggle(false)
					}}
					onMouseLeave={() => specificToggle(true)}>
					<i
						className='fas fa-angle-double-right cursor-pointer txt-color-white'
						onClick={toggleHandler}
					/>
				</div>
			)}
			<Menu
				items={menu}
				mode='inline'
				selectedKeys={[activeKey]}
				defaultSelectedKeys={['1']}
				openKeys={[...map(menu, 'key')]}
				expandIcon={<></>}
				inlineIndent={0}
			/>
		</Sider>
	)
}

export default Sidebar
